import * as React from 'react';
import { Mention } from 'react-twitter-widgets';

import { Translate, useI18nContext, __ } from '../react-i18n';
import { Button, Flex, Modal, Box } from '../ui';
import useModal from '../hooks/useModal';

interface IProps {
  latitude: number;
  longitude: number;
}

export default ({ latitude, longitude }: IProps) => {
  const i18nContext = useI18nContext();
  const { modalOpened, showModal, hideModal } = useModal();

  const emailSubject: string = __({ id: 'poi:settle-in-modal:mail-subject', defaultMessage: 'Hi Martin, I want to expatriate' }, i18nContext);
  const emailBody: string = __(
    {
      id: 'poi:settle-in-modal:mail-body',
      defaultMessage: `
...

Hi Martin, please help me expatriate
- Where are you going?:
here is the exact point: ${window.location.href}
-

- Where are you coming from?:

- What's your phone number (WhatsApp ideally)?:

- What do you need help with?: ([X] where appropriate)
[] I need a work permit
[] I need help with getting my visa
[] I need an long term accomodation
[] I need a short term accomodation
[] I need help considering tax in this country
[] I need a job around there

I'll get back to you as soon as I can

Thanks

${latitude},${longitude}
`,
    },
    i18nContext
  );

  const searchParams = new URLSearchParams();
  searchParams.set('subject', emailSubject);
  searchParams.set('body', emailBody);

  return (
    <>
      <Button primary size="xxsmall" color="main" onClick={showModal}>
        <Translate id="poi:settle-in-modal:button" defaultMessage="Get me a work permit" />
      </Button>
      <Modal open={modalOpened} onClose={hideModal} size="small">
        <Modal.Content>
          <h2>
            <Translate id="poi:settle-in-modal:title" defaultMessage="I'm here to help" />
          </h2>
          <h6>
            <Translate
              id="poi:settle-in-modal:subtitle"
              defaultMessage="Hi, my name is Martin and I'm the creator of remote-family.com and a real human being. I will assist you personally with your expatriation, so please, let me know exactly what you need by email"
            />
          </h6>
          <Box margin="small">
            <a target="_blank" href={`mailto:hello@remote-family.com?${searchParams}`} rel="noopener noreferrer">
              hello@remote-family.com
            </a>
          </Box>
          <Box margin="small">
            <Flex marginHorizontal="xsmall">
              <Translate id="poi:add-twitter:description" defaultMessage="Or alternatively you can" />
              <Mention
                username="remote_family"
                options={{
                  text: __(
                    {
                      id: 'poi:add-modal:feedback:twitter',
                      defaultMessage: 'Hey, here is a remote spot which should be included : ',
                    },
                    i18nContext
                  ),
                }}
              />
            </Flex>
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Button tertiary color="primary" size="small" onClick={hideModal}>
            <Translate id="poi:settle-in-modal:cancel" defaultMessage="In fact, no thanks" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
