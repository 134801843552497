import React, { useState } from 'react';
import { Slider } from 'react-semantic-ui-range';
import styled from 'styled-components';
import memoize from 'lodash/fp/memoize';
import CountryDataTable from '../app-components/CountryDataTable';

import { Flex, Badge, Popup, Button, Modal, Icon } from '../ui';

const Wrapper = styled('div')`
  width: 250px;
  .semantic_ui_range_inner > div:nth-child(2) {
    background: linear-gradient(90deg, blue 0%, orange 50%, red 100%);
  }
`;

interface IProps {
  defaultTemperature: number[];
  onChange: (values: any[]) => any;
  onLoad: (values: any[]) => any;
  badges?: boolean;
  countries?: any[];
}

const MIN = -10;
const MAX = 30;

const range = (start: number, end: number) => Array.from({ length: end - start }, (v, k) => k + start);
const format = (val: any) => `${val.name}, ${val.averageTemperature}°c`;

const getAverageTemperaturesArray = memoize((countries: any) => {
  const rangeTemperatures = range(MIN, MAX + 1);
  const existingTemperatures: any = {};
  const finalTemperatures: any = {};

  countries.forEach((val: any) => {
    const floor = Math.floor(val.averageTemperature);
    const ceil = Math.ceil(val.averageTemperature);
    const chosenTemperature = val.averageTemperature % 1 >= 0.5 ? ceil : floor;
    const value = format(val);

    if (existingTemperatures[chosenTemperature]) {
      existingTemperatures[chosenTemperature].push(value);
    } else {
      existingTemperatures[chosenTemperature] = [value];
    }
  });

  let lastTemperature = null;

  for (const temp of rangeTemperatures) {
    const temperature = rangeTemperatures[temp];
    finalTemperatures[temperature] = existingTemperatures[temperature] || lastTemperature;
    lastTemperature = finalTemperatures[temperature];
  }

  return finalTemperatures;
});

export default ({ defaultTemperature, onChange, onLoad, badges, countries, ...rest }: IProps) => {
  // const { data: { countries: nodes = {} } = {} } = useQuery(GET_COUNTRIES);
  const [min, setMin] = useState(defaultTemperature[0] || MIN);
  const [max, setMax] = useState(defaultTemperature[1] || MAX);

  if (badges) {
    if (min === MIN && max === MAX) {
      return null;
    }

    return (
      <>
        <Badge secondary customColor="blue" key="temperature_min">
          {min}°C
        </Badge>
        <Badge secondary customColor="red" key="temperature_max">
          {max}°C
        </Badge>
      </>
    );
  }
  const temperatures = getAverageTemperaturesArray(countries);
  const minTemperatures = temperatures[min] || [];
  const maxTemperatures = temperatures[max] || [];

  const settings = {
    start: [min, max],
    min: MIN,
    max: MAX,
    step: 1,
    onChange: ([newMin, newMax]: any) => {
      setMin(newMin);
      setMax(newMax);
      onChange([newMin, newMax]);
    },
  };

  return (
    <Wrapper>
      <Slider multiple color="red" settings={settings} />
      <Flex alignItems="space-between">
        <Flex.Item flex="1" style={{ textAlign: 'left' }}>
          {min}°C
        </Flex.Item>
        <Flex.Item flex="1" style={{ textAlign: 'right' }}>
          {max}°C
        </Flex.Item>
      </Flex>
      <Flex alignItems="space-between">
        <Flex.Item flex="1" style={{ textAlign: 'left' }}>
          {minTemperatures.length > 0 && (
            <Popup trigger={<em>{minTemperatures[0].split(',')[0]}</em>} inverted position="bottom left">
              <Popup.Content>
                {minTemperatures.map((t: string) => (
                  <div key={t}>{t}</div>
                ))}
              </Popup.Content>
            </Popup>
          )}
        </Flex.Item>
        <Flex.Item>
          <Modal
            trigger={
              <Button secondary color="faded" size="xsmall">
                <Icon name="list" />
              </Button>
            }
          >
            <Modal.Content>
              <CountryDataTable countries={countries} fields={['name', 'averageTemperature']} />
            </Modal.Content>
          </Modal>
        </Flex.Item>
        <Flex.Item flex="1" style={{ textAlign: 'right' }}>
          {maxTemperatures.length > 0 && (
            <Popup trigger={<em>{maxTemperatures[0].split(',')[0]}</em>} inverted position="bottom right">
              <Popup.Content>
                {maxTemperatures.map((t: string) => (
                  <div key={t}>{t}</div>
                ))}
              </Popup.Content>
            </Popup>
          )}
        </Flex.Item>
      </Flex>
    </Wrapper>
  );
};
