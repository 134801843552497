import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Icon } from '../../../ui';
import { Translate, useI18nContext, __ } from '../../../react-i18n';
import MapButton from '../../../app-components/MapButton';

interface IProps {
  latitude: number;
  longitude: number;
  onClick: (airports: any[]) => void;
}

export default ({ latitude, longitude, onClick }: IProps) => {
  const { locale } = useI18nContext();
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
  }, [latitude, longitude]);

  const onSearch = async () => {
    setLoading(true);
    setLoaded(true);
    const { data } = await axios.get(`https://nano.aviasales.ru/places/coords_to_places_${locale}.json?coords=${latitude},${longitude}`);
    setLoading(false);

    if (data.length === 0) {
      alert(__({ id: 'airport:near:none', defaultMessage: 'We did not find any airports close to this location' }));
    }
    return onClick(data);
  };

  return (
    <MapButton primary size="xsmall" color="light" onClick={onSearch} disabled={loaded}>
      <Icon name={loading ? 'loader' : 'airport'} spin={loading} />
      <Translate id="airport:near:button" defaultMessage="Airports" />
    </MapButton>
  );
};
