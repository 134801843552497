import React from 'react';
import PropTypes from 'prop-types';
import { darken, lighten, transparentize, getLuminance } from 'polished';
import styled, { css } from 'styled-components';

import { rotate, block, fontWeights, media } from '../../theme/mixins';
import { getInvertColor } from '../../theme/style-utils';

import control from './controls';

const Label = styled.span`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function addPrimaryColor(color) {
  const isDark = getLuminance(color) > 0.5;
  const hoverColor = isDark ? darken(0.1, color) : lighten(0.1, color);
  const activeColor = darken(0.05, color);
  const focusBoxShadowColor = transparentize(0.7, color);
  return css`
    ${({ theme }) => css`
      background-color: ${color};
      border-color: ${color};
      color: ${getInvertColor(color)};

      > a {
        color: ${getInvertColor(color)}!important;
      }

      &:hover:not([disabled]) {
        background-color: ${hoverColor};
        border-color: ${hoverColor};
        color: ${getInvertColor(color)};
      }

      &:focus:not([disabled]) {
        &:not(:active):not(:hover) {
          box-shadow: ${theme.Button.focus.boxShadowSize} ${focusBoxShadowColor};
        }
      }

      &:active:not([disabled]) {
        background-color: ${activeColor};
        border-color: ${activeColor};
      }
    `}
  `;
}

function addSecondaryColor(color) {
  const hoverColor = color;
  const activeColor = darken(0.05, color);
  const focusBoxShadowColor = transparentize(0.7, color);
  return css`
    ${({ theme }) => css`
      background-color: transparent;
      border-color: ${color};
      color: ${color};

      > a {
        color: ${getInvertColor(color)}!important;
      }

      &:hover:not([disabled]) {
        background-color: ${hoverColor};
        color: ${getInvertColor(color)};
        border-color: ${hoverColor};
      }

      &:focus:not([disabled]) {
        &:not(:active):not(:hover) {
          box-shadow: ${theme.Button.focus.boxShadowSize} ${focusBoxShadowColor};
        }
      }

      &:active:not([disabled]) {
        background-color: ${activeColor};
        color: ${getInvertColor(color)};
        border-color: ${activeColor};
      }
    `}
  `;
}

function addTertiaryColor(color) {
  const activeColor = darken(0.05, color);
  const focusBoxShadowColor = transparentize(0.7, color);
  return css`
    ${({ theme }) => css`
      background-color: transparent;
      border-color: transparent;
      color: ${color};

      > a {
        color: ${getInvertColor(color)}!important;
      }

      &:hover:not([disabled]) {
        background-color: ${theme.Button.hover.backgroundColor};
        border-color: ${theme.Button.hover.borderColor};
        color: ${color};
      }

      &:focus:not([disabled]) {
        &:not(:active):not(:hover) {
          box-shadow: ${theme.Button.focus.boxShadowSize} ${focusBoxShadowColor};
        }
      }

      &:active:not([disabled]) {
        background-color: ${theme.Button.active.backgroundColor};
        border-color: ${theme.Button.active.borderColor};
        color: ${activeColor};
      }
    `}
  `;
}

function addLoadingColor(color) {
  return css`
    &:after {
      border-left-color: ${color};
      border-bottom-color: ${color};
    }
  `;
}

function addLabelledColor(color) {
  const activeColor = darken(0.05, color);
  const focusBoxShadowColor = transparentize(0.7, color);
  return css`
    ${({ theme, labelWidth }) => css`
      box-shadow: inset ${labelWidth} 0 0 0 ${color};

      & > ${Label}, & > i,
      & > svg {
        color: ${getInvertColor(color)};
      }

      &:focus:not([disabled]) {
        &:not(:active):not(:hover) {
          box-shadow: inset ${labelWidth} 0 0 0 ${activeColor}, ${theme.Button.focus.boxShadowSize} ${focusBoxShadowColor};
        }
      }

      &:active {
        box-shadow: inset ${labelWidth} 0 0 0 ${activeColor};
      }
    `}
  `;
}

const Button = styled(
  ({
    as = 'button',
    loading,
    customColor,
    labelWidth,
    primary,
    secondary,
    tertiary,
    textTransform,
    fullWidth,
    labelled,
    lowercase,
    multiLine,
    margin,
    marginHorizontal,
    marginVertical,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    textTranform,
    ...props
  }) => React.createElement(as, props)
)`
  ${control};
  ${block};
  ${fontWeights}
  ${({ theme, textTransform }) => css`
    user-select: none;
    border-width: 1px;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    text-transform: ${textTransform};
    text-decoration: none;
    appearance: none!important;
    font-family: ${theme.Button.fontFamily};

    & > ${Label},
    & > i:first-child,
    & > svg:first-child {
      margin-right: 3px;
    }

    & > ${Label},
    & > i:last-child,
    & > svg:last-child {
      margin-left: 3px;
    }

    &[disabled] {
      opacity: ${theme.Button.disabled.opacity};
      box-shadow: none;
    }

    ${({ loading }) =>
      loading &&
      css`
        color: transparent !important;
        pointer-events: none;

        &:after {
          position: absolute;
          left: calc(50% - (1em / 2));
          top: calc(50% - (1em / 2));

          content: '';
          display: block;
          height: 1em;
          width: 1em;

          animation: ${rotate} 500ms infinite linear;
          border: 2px solid;
          border-radius: 100px;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      `};


    ${({ labelled, labelWidth }) =>
      labelled &&
      css`
        position: relative;
        padding-left: calc(0.625em - 1px + ${labelWidth});

        & > ${Label}, & > i,
        & > svg {
          position: absolute;
          top: 50%;
          left: 0;
          line-height: 1;
          transform: translateY(-50%);
          margin: 0;
          width: ${labelWidth};
          margin-left: -1px;
        }
      `};
    & > i {
      display: inline-flex!important; // to bypass semantic-ui-react
      align-items: center!important; // to bypass semantic-ui-react
      height: 100%!important; // to bypass semantic-ui-react
    }
    ${({ lowercase }) =>
      lowercase &&
      css`
        text-transform: lowercase;
      `}
    ${({ fullWidth }) => css`
      ${typeof fullWidth === 'string' &&
        css`
          ${media[fullWidth]`
          display: flex;
          width: 100%;
        `}
        `}
      ${typeof fullWidth === 'boolean' &&
        fullWidth &&
        css`
          display: flex;
          width: 100%;
        `}
    `}
    ${({ multiLine }) =>
      multiLine &&
      css`
        flex-direction: column;
        height: auto;
      `}

    ${({ size }) =>
      size &&
      css`
      ${['xxsmall', 'xsmall', 'small'].includes(size) &&
        css`
          height: 2.45em;
        `}

      font-size: ${theme.Button.sizes[size] ? `${theme.Button.sizes[size]}rem` : size};
    `};

    /* Generated colors */
    ${Object.keys(theme.colors).map(
      colorKey => css`
        ${({ primary, secondary, tertiary, loading, labelled, color }) =>
          color === colorKey &&
          css`
        ${tertiary &&
          css`
            ${addTertiaryColor(theme.colors[colorKey])};
            ${loading &&
              css`
                ${addLoadingColor(theme.colors[colorKey])}
              `}
          `}
        ${secondary &&
          css`
            ${addSecondaryColor(theme.colors[colorKey])};
            ${loading &&
              css`
                ${addLoadingColor(theme.colors[colorKey])}
              `}
            ${labelled &&
              css`
                ${addLabelledColor(theme.colors[colorKey])}
              `}
          `}
        ${primary &&
          css`
            ${addPrimaryColor(theme.colors[colorKey])};
            ${loading &&
              css`
                ${addLoadingColor(getInvertColor(theme.colors[colorKey]))}
              `}
          `}
      `};
      `
    )}

    ${({ primary, secondary, tertiary, loading, labelled, customColor }) =>
      customColor &&
      css`
      ${tertiary &&
        css`
          ${addTertiaryColor(customColor)};
          ${loading &&
            css`
              ${addLoadingColor(customColor)}
            `}
        `}
      ${secondary &&
        css`
          ${addSecondaryColor(customColor)};
          ${loading &&
            css`
              ${addLoadingColor(customColor)}
            `}
          ${labelled &&
            css`
              ${addLabelledColor(customColor)}
            `}
        `}
      ${primary &&
        css`
          ${addPrimaryColor(customColor)};
          ${loading &&
            css`
              ${addLoadingColor(getInvertColor(customColor))}
            `}
        `}
    `};
  `}
`;

Button.Label = Label;
Button.Text = styled.div`
  font-size: 0.7em;
`;
Button.Value = styled.div``;

Button.Void = styled(
  ({
    as = 'span',
    margin,
    marginHorizontal,
    marginVertical,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    ...props
  }) => React.createElement(as, props)
)`
  ${block};
  cursor: pointer;
`;

/* eslint-disable consistent-return */
Button.propTypes = {
  primary: props => {
    if (!(props.primary || props.secondary || props.tertiary)) {
      return new Error('One of props `primary`, `secondary` or `tertiary` was not specified in Button.');
    }
    return null;
  },
  secondary: props => {
    if (!(props.primary || props.secondary || props.tertiary)) {
      return new Error('One of props `primary`, `secondary` or `tertiary` was not specified in Button.');
    }
    return null;
  },
  tertiary: props => {
    if (!(props.primary || props.secondary || props.tertiary)) {
      return new Error('One of props `primary`, `secondary` or `tertiary` was not specified in Button.');
    }
    return null;
  },
  type: PropTypes.oneOfType([PropTypes.string, PropTypes.symbol]),
  fontWeight: PropTypes.string,
  loading: PropTypes.bool,
  labelled: PropTypes.bool,
  customColor: PropTypes.string,
  labelWidth: PropTypes.string,
  textTransform: PropTypes.string,
  lowercase: PropTypes.bool,
  multiLine: PropTypes.bool,
  fullWidth: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Button.defaultProps = {
  primary: null,
  secondary: null,
  tertiary: null,
  type: 'button',
  fontWeight: 'semibold',
  loading: null,
  labelled: null,
  customColor: null,
  labelWidth: '2em',
  lowercase: false,
  multiLine: false,
  textTransform: 'none',
  fullWidth: false,
};

Button.Group = styled.div`
  display: flex;
  align-items: center;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
      & > ${Button} {
        flex: 1;
      }
    `}

  & > ${Button} {
    height: 100%;

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

/* eslint-enable consistent-return */
export default Button;
