import React, { useRef, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { get } from 'lodash/fp';
import { Map, TileLayer, ZoomControl, Circle, LayersControl, ScaleControl } from 'react-leaflet';
import Control from 'react-leaflet-control';

import MapMarkers from '../app-components/MapMarkers';
import ModalAddPoi from '../app-components/ModalAddPoi';
import ModalSearchMore from '../app-components/ModalSearchMore';
import ModalSettleIn from '../app-components/ModalSettleIn';
import AirportNearButton from '../modules/airports/components/AirportNearButton';
import HospitalNearButton from '../modules/hospitals/components/HospitalNearButton';
import AirportMarkers from '../modules/airports/components/AirportMarkers';
import Ad from '../modules/advertisement/components/Ad';
import PolygonAreas from '../app-components/PolygonAreas';
import usePrevious from '../hooks/usePrevious';
import useArray from '../hooks/useArray';
import { formatBounds, simplifyBounds, getCenterFromBounds, getCornersFromBounds } from '../utils/geo';
import { IPoi } from '../types.d';
import { Flex, Box, Color, Loader, Icon, Drawer } from '../ui';
import { Translate } from '../react-i18n';

const { BaseLayer } = LayersControl;

const SHOW_DRAWER_INSIGHT_TIME = 3000;

const GET_POIS = gql`
  query listPois($geohashCode: String, $bounds: [[Float]], $categories: [ID]) {
    pois: listPois(geohashCode: $geohashCode, bounds: $bounds, categories: $categories) {
      count
      limit
      nodes {
        _id
        name
        altName
        address
        country
        googlePlaceId
        category {
          _id
          name
          icon
          color
          inverseColor
          shape
        }
        location {
          type
          coordinates
        }
        website
        phone
        verified
        rinkresults {
          url
        }
        shakabay {
          _id
          url
        }
        windguru {
          _id
          url
        }
        padi {
          _id
          url
          features
        }
        leadingcourses {
          _id
          url
        }
        coworker {
          _id
          url
        }
        fieldworkeducation {
          ieyc
          ipc
          imyc
          accredited
          url
        }
        cobis {
          url
          schoolType
          image
        }
        cambridgeinternational {
          url
          privateCandidateAccepted
        }
        citypopulation {
          url
          population
        }
        alltrails {
          _id
          url
          distance
          elevation_gain
          route_type
        }
        worldheritagelist {
          _id
          url
          property
          bufferZone
          dateInscription
          danger
        }
        skateboard {
          _id
          url
        }
      }
    }
  }
`;
const REPORT_POI = gql`
  mutation reportPoi($id: ID) {
    reportPoi(_id: $id) {
      result {
        _id
      }
    }
  }
`;

type TLatLngBounds = any;

interface IProps {
  categories?: string[];
  areas: any[];
  geohashCode?: string;
  bounds: TLatLngBounds;
  onMapMove?: any;
  onAreaClick: (code: any, hash: string) => void;
}

interface IVariables {
  categories?: string[];
  geohashCode?: string;
  bounds: TLatLngBounds;
}

const NearActionsButtons = styled(Flex).attrs({ marginHorizontal: 'small', justifyContent: 'center' })``;

const Wrapper = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
`;

const mapStyle: any = { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 };

const AREA_THRESHOLD = 10;

export default React.memo(
  ({ geohashCode, categories, bounds: initialBounds, onMapMove, areas, onAreaClick }: IProps) => {
    const leafletMap = useRef(null);
    const prevGeohashCode = usePrevious(geohashCode);
    const [circleCenter, setCircleCenter] = useState<any>(null);
    const [circleRadius, setCircleRadius] = useState(20);
    const [currentZoom, setCurrentZoom] = useState(AREA_THRESHOLD);
    const updateCircleRadius = (e: any) => setCircleRadius(e.target.value);
    const [bounds, setBounds] = useState<TLatLngBounds | undefined>(formatBounds(initialBounds));
    const [boundsSet, setBoundsSet] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const toggleExpanded = () => setExpanded(!expanded);
    const [center, setCenter] = useState(getCenterFromBounds(initialBounds));
    const airports = useArray([]);
    const [latitude, longitude] = center;

    const variablesGeohash: IVariables = { bounds: simplifyBounds(initialBounds), categories };

    const { data, refetch, loading } = useQuery(GET_POIS, {
      variables: variablesGeohash,
      skip: !geohashCode || currentZoom < AREA_THRESHOLD || !boundsSet || currentZoom === 20,
    });
    const [reportPoiMutation] = useMutation(REPORT_POI);

    const pois: IPoi[] = get('pois.nodes')(data) || [];

    useEffect(() => {
      setTimeout(() => setExpanded(false), SHOW_DRAWER_INSIGHT_TIME);
    }, []);

    useEffect(() => {
      // we only set bounds when geohashCode has changed and not when only zoom has changed
      if (initialBounds && !boundsSet) {
        setBoundsSet(true);
        setBounds(formatBounds(initialBounds));
        const leafletElement = get('current.leafletElement')(leafletMap);
        setCurrentZoom(leafletElement.getZoom());
      }

      if (prevGeohashCode !== geohashCode) {
        setBoundsSet(false);
      }
    }, [geohashCode, initialBounds, pois]);

    const onMove = () => {
      const leafletElement = get('current.leafletElement')(leafletMap);
      if (leafletElement) {
        const {
          _southWest: { lat: latSW, lng: lngSW },
          _northEast: { lat: latNE, lng: lngNE },
        } = leafletElement.getBounds();
        const { lat, lng } = leafletElement.getCenter();
        setCenter([lat, lng]);

        const newBounds: TLatLngBounds = [[lngSW, latSW], [lngSW, latNE], [lngNE, latNE], [lngNE, latSW], [lngSW, latSW]];
        if (onMapMove) {
          onMapMove(newBounds);
        }

        const nextZoom = leafletElement.getZoom();

        if (
          nextZoom <= currentZoom &&
          nextZoom >= AREA_THRESHOLD &&
          JSON.stringify(simplifyBounds(formatBounds(bounds))) !== JSON.stringify(simplifyBounds(newBounds))
        ) {
          // should abort all pending queries https://github.com/apollographql/apollo-client/issues/4150
          refetch({
            categories,
            bounds: simplifyBounds(newBounds),
          });
        }

        setCurrentZoom(nextZoom);
      }
    };

    const handleClick = (event: any) => {
      setCircleCenter(event.latlng);
    };

    const handleRemove = async (poiId: string) => {
      if (window.confirm('Are you sure?')) {
        reportPoiMutation({
          variables: { id: poiId },
          optimisticResponse: {
            __typename: 'Mutation',
            reportPoi: { result: { _id: poiId, __typename: 'Poi' }, __typename: 'reportPoiOutput' },
          },
        });
        refetch();
      }
    };

    const handleAddAirports = (airportsLocations: any) => {
      const leafletElement = get('current.leafletElement')(leafletMap);
      if (leafletElement) {
        const currentBounds = leafletElement.getBounds();
        for (const airportLocation of airportsLocations) {
          currentBounds.extend(airportLocation.coordinates);
        }
        leafletElement.fitBounds(currentBounds);
      }

      airports.setValue(airportsLocations);
    };

    const { neLat, neLng, swLat, swLng } = getCornersFromBounds(bounds);

    const onAreaCodeClick = (code: any, hash: string) => (e: any) => onAreaClick(code, hash);

    return (
      <Wrapper>
        <Map
          zoomControl={false}
          style={mapStyle}
          onMoveend={onMove}
          ref={leafletMap}
          bounds={bounds || [[0, 0], [1, 1]]}
          minZoom={2}
          maxBounds={[[-90, -180], [90, 180]]}
          maxBoundsViscosity={1}
          maxZoom={19}
        >
          <ZoomControl position="topright" />
          <LayersControl position="topright">
            <BaseLayer checked name="Map">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer name="Satellite">
              <TileLayer
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
            </BaseLayer>
            <BaseLayer name="Surfer Roads">
              <TileLayer
                attribution='Imagery from <a href=\"http://giscience.uni-hd.de/\">GIScience Research Group @ University of Heidelberg</a> | Map data &copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors'
                url="https://maps.heigit.org/openmapsurfer/tiles/roads/webmercator/{z}/{x}/{y}.png"
              />
            </BaseLayer>
          </LayersControl>
          {circleCenter && (
            <Control position="bottomright" className="leaflet-bar">
              <Box padding="xxsmall" color="white">
                <Flex alignItems="center" marginHorizontal="small">
                  <Flex.Item flex="1" as="input" type="range" name="radius" min="5" max="50" value={circleRadius} onChange={updateCircleRadius} />
                  <Color color="main">
                    <b>{circleRadius} km</b>
                  </Color>
                </Flex>
              </Box>
            </Control>
          )}
          {loading && (
            <Control position="bottomright">
              <Loader width={30} height={30} borderWidth={2} />
            </Control>
          )}
          <ScaleControl position="bottomright" />
          {circleCenter && <Circle center={circleCenter} radius={circleRadius * 1000} color="#1abc9c" fillColor="#1abc9c" />}
          {currentZoom >= AREA_THRESHOLD && <MapMarkers pois={pois} onClick={handleClick} onRemove={handleRemove} />}
          {currentZoom < AREA_THRESHOLD && <PolygonAreas areas={areas} onAreaCodeClick={onAreaCodeClick} />}
          <AirportMarkers airports={airports.value} />
        </Map>
        <Drawer expanded={expanded}>
          <Drawer.Toggler onClick={toggleExpanded}>
            {!expanded && (
              <Flex as="span" marginHorizontal="xsmall" alignItems="center" justifyContent="center">
                <Icon name="up" />
                <Translate id="found" defaultMessage="Found your sweet spot, click here" />
                <Icon name="up" />
              </Flex>
            )}
            {expanded && (
              <Flex as="span" marginHorizontal="xsmall" alignItems="center" justifyContent="center">
                <Icon name="down" />
                <Translate id="found:close" defaultMessage="Back to the map" />
                <Icon name="down" />
              </Flex>
            )}
          </Drawer.Toggler>
          <Drawer.Content>
            <h3>
              <Translate id="found:search" defaultMessage="Search around" />
            </h3>
            <NearActionsButtons>
              <ModalSearchMore latitude={latitude} longitude={longitude} zoom={currentZoom} neLat={neLat} neLng={neLng} swLat={swLat} swLng={swLng} />
              <AirportNearButton latitude={latitude} longitude={longitude} onClick={handleAddAirports} />
              <HospitalNearButton latitude={latitude} longitude={longitude} zoom={currentZoom} />
            </NearActionsButtons>
            <h3>
              <Translate id="found:settle-in" defaultMessage="Help me settle in!" />
            </h3>
            <NearActionsButtons>
              <ModalSettleIn latitude={latitude} longitude={longitude} />
              <Ad brand="safetywing" withImage={false} withPromotion={false} withText={false} />
            </NearActionsButtons>
            <hr />
            <h5>
              <Translate id="found:poi" defaultMessage="Want to help the community!" />
            </h5>
            <NearActionsButtons>
              <ModalAddPoi />
            </NearActionsButtons>
          </Drawer.Content>
        </Drawer>
      </Wrapper>
    );
  },
  (prevProps: IProps, nextProps: IProps) => {
    return (
      JSON.stringify(prevProps.categories) === JSON.stringify(nextProps.categories) &&
      JSON.stringify(prevProps.bounds) === JSON.stringify(nextProps.bounds) &&
      prevProps.areas.length === nextProps.areas.length &&
      prevProps.geohashCode === nextProps.geohashCode
    );
  }
);
