import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get } from 'lodash/fp';

import { Box, Flex, Button, Loader } from '../ui';
import { ICategoryRequest } from '../types';

const GET_CATEGORY_REQUEST = gql`
  query categoryRequests {
    categoryRequests(orderBy: "nbRequests:desc") {
      count
      nodes {
        _id
        name
        nbRequests
      }
    }
  }
`;

interface IProps {
  onClick: (name: string) => void;
}

export default ({ onClick }: IProps) => {
  const { data, loading } = useQuery(GET_CATEGORY_REQUEST);

  const categoryRequests = get('categoryRequests.nodes')(data) || [];
  const handleClick = (name: string) => () => onClick(name);

  return (
    <Box margin="medium" textAlign={loading ? 'center' : 'left'}>
      {loading && <Loader />}
      <Flex wrap="wrap" marginHorizontal="small">
        {categoryRequests.map((cat: ICategoryRequest) => (
          <Button size="xxsmall" key={`${cat._id}`} color="dark" onClick={handleClick(cat.name || '')}>
            {cat.name} ({cat.nbRequests})
          </Button>
        ))}
      </Flex>
    </Box>
  );
};
