import Leaflet from 'leaflet';
import 'react-leaflet-markercluster/dist/styles.min.css';

Leaflet.Polygon.addInitHook(function() {
  this._latlng = this._bounds.getCenter();
});

Leaflet.Polygon.include({
  getLatLng: function() {
    return this._latlng;
  },
  setLatLng: function() {},
});
