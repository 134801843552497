import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import * as Sentry from '@sentry/browser';

import './index.css';
import 'semantic-ui-css/semantic.min.css';

import App from './App';
import * as serviceWorker from './serviceWorker';

import typography from './theme/typography';
import mainTheme from './theme/theme';

import './react-i18n/bootstrap';
import { IntlProvider } from './react-i18n';

import ScrollHandler from './components/ScrollHandler';
import AppProvider from './app-providers/AppProvider';
import SEO from './app-components/SEO';

import './components/Form/form.css';
typography.injectStyles();

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
}

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_ENDPOINT,
  fetchOptions: {
    credentials: 'include',
  },
  credentials: 'include',
});

const LANGUAGES = process.env.REACT_APP_I18N_LANGUAGES ? process.env.REACT_APP_I18N_LANGUAGES.split(',') : 'en';

ReactDOM.render(
  <IntlProvider locales={LANGUAGES} defaultLocale="en">
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ThemeProvider theme={mainTheme}>
          <AppProvider>
            <SEO init />
            <ScrollHandler />
            <App />
          </AppProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ApolloProvider>
  </IntlProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
