import * as React from 'react';

import { Icon } from '../../../ui';
import { Translate, useI18nContext, __ } from '../../../react-i18n';
import MapButton from '../../../app-components/MapButton';

interface IProps {
  latitude: number;
  longitude: number;
  zoom: number;
}

const getMapsUrl = (latitude: number, longitude: number, zoom: number, search: string) =>
  `https://www.google.com/maps/search/${search}/@${latitude},${longitude},${zoom}z`;

export default ({ latitude, longitude, zoom }: IProps) => {
  const i18nContext = useI18nContext();

  const i18nHospitalSearchName = __({ id: 'hospital:search', defaultMessage: 'hospital' }, i18nContext);

  return (
    <MapButton
      as="a"
      primary
      size="xsmall"
      color="light"
      href={getMapsUrl(latitude, longitude, zoom, i18nHospitalSearchName)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon name="hospital" />
      <Translate id="hospital:near:button" defaultMessage="Hospitals" />
    </MapButton>
  );
};
