import React from 'react';
import { Marker, MarkerProps } from 'react-leaflet';
import * as L from 'leaflet';
import styled, { css } from 'styled-components';
import { renderToString } from 'react-dom/server';

import { TIconName } from '../icons';
import { Icon } from '../ui';

import 'leaflet-extra-markers/dist/js/leaflet.extra-markers.js';
import 'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css';

type IShape = 'square';

interface IConfig {
  icon: TIconName;
  color: string;
  backgroundColor: string;
  shape: IShape;
}

const IconWrapper = styled('span')<{ shape: 'square'; color: string; backgroundColor: string }>`
  ${({ backgroundColor, color }) => css`
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -1.5rem;
    top: -1.5rem;
    position: relative;
    border-radius: 3rem 3rem 0;
    transform: rotate(45deg);
    color: ${color};
    background-color: ${backgroundColor};
    border: 1px solid ${color};

    svg {
      color: ${color};
      transform: rotate(-45deg);
      font-size: 20px;
    }
  `}
`;

const markerIcon = (config: IConfig) =>
  // @ts-ignore
  L.divIcon({
    iconAnchor: [0, 24],
    // labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: renderToString(
      <IconWrapper shape={config.shape} backgroundColor={config.backgroundColor} color={config.color}>
        <Icon name={config.icon} />
      </IconWrapper>
    ),
  });

export interface IProps {
  color: string;
  inverseColor: string;
  shape: IShape;
  children: any;
  position: any;
  icon: TIconName;
}

export default ({ icon, color, inverseColor, shape, children, position, ...props }: IProps & MarkerProps & { icon: string }) => {
  return (
    <Marker icon={markerIcon({ shape, color: inverseColor, backgroundColor: color, icon })} position={position} {...props}>
      {children}
    </Marker>
  );
};
