import React from 'react';
import styled, { css } from 'styled-components';
import { block, textAligns } from '../../theme/mixins';
import { getInvertColor, getShadowTransparency } from '../../theme/style-utils';

export interface IBoxProps extends React.FunctionComponent {
  children: any;
  as?: any;
  color?: string;
  customColor?: string;
  alignItems?: string;
  direction?: string;
  textAlign?: string;
  justifySelf?: string;
  justifyContent?: string;
  keepFirstMargin?: boolean;
  keepLastMargin?: boolean;
  marginHorizontal?: string;
  marginVertical?: string;
  marginBottom?: string;
  marginTop?: string;
  paddingHorizontal?: string;
  paddingVertical?: string;
}

const Box = styled<any>(
  ({
    as = 'div',
    alignItems,
    marginHorizontal,
    marginVertical,
    marginBottom,
    marginTop,
    paddingHorizontal,
    paddingVertical,
    textAlign,
    ...props
  }: IBoxProps) => React.createElement(as, props)
)`
  ${({ theme, rounded, customColor, shadowed, color }) => css`
    /* Generated colors */
    ${Object.keys(theme.colors).map(
      (colorKey: string) => css`
        ${color === colorKey &&
          css`
            background-color: ${theme.colors[colorKey]};
            color: ${getInvertColor(theme.colors[colorKey])};
            ${shadowed &&
              css`
                box-shadow: 0 0 ${theme.Box.shadow.spread} ${theme.Box.shadow.blur}
                  ${getShadowTransparency(theme.Box.shadow.transparency, theme.colors[colorKey])};
              `}
          `}
      `
    )};

    ${customColor &&
      css`
        background-color: ${customColor};
        color: ${getInvertColor(customColor)};
        ${shadowed &&
          css`
            box-shadow: 0 0 ${theme.Box.shadow.spread} ${theme.Box.shadow.blur} ${getShadowTransparency(theme.Box.shadow.transparency, customColor)};
          `}
      `}

    ${rounded &&
      css`
        border-radius: ${theme.Box.borderRadius};
      `}

    ${block}
    ${textAligns}
  `}
`;

export default Box;
