const BoxTheme = {
  borderRadius: '4px',
  shadow: {
    blur: 0,
    spread: '15px',
    transparency: 0.5,
  },
};

export default BoxTheme;
