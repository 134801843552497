import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';

const Preloader = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const spin = keyframes`
  0% {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }`;

const Loader = styled.div<{ width: number; height: number; borderWidth: number }>`
  ${({ width, height, borderWidth }) => css`
    display: block;
    position: relative;
    width: ${width}px;
    height: ${height}px;
    border-radius: 50%;
    border: ${borderWidth}px solid transparent;
    border-top-color: #2b303a;
    animation: ${spin} 1s linear infinite;

    &:before,
    &:after {
      content: '';
      position: absolute;
      border-radius: 50%;
      border: ${borderWidth}px solid transparent;
    }

    &:before {
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      border-top-color: #90cfca;
      animation: ${spin} 2s linear infinite;
    }

    &:after {
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      border-top-color: #911b50;
      animation: ${spin} 1.5s linear infinite;
    }
  `}
`;

export default ({ width = 60, height = 60, borderWidth = 2 }: { height?: number; width?: number; borderWidth?: number }) => {
  return (
    <Preloader>
      <Loader width={width} height={height} borderWidth={borderWidth} />
    </Preloader>
  );
};
