import styled, { css, keyframes } from 'styled-components';

export const slideAnimation = keyframes`
  0% {transform:translateX(-100%);}
  100% {transform:translateX(100%);}
`;

export default styled('div')<{
  checked?: boolean;
  empty?: boolean;
  backgroundUrl?: string;
  color?: string;
  inverseColor?: string;
  direction?: 'column' | 'row';
}>`
  ${({ checked, empty, color, inverseColor, theme, direction = 'row' }) => css`
    display: flex;
    align-items: ${direction === 'row' ? 'flex-start' : 'center'};
    justify-content: stretch;
    cursor: pointer;
    transition: all 1s ease;
    border: 4px solid #fff;
    border-radius: 8px;
    flex: 1 0 98%;
    flex-direction: ${direction};
    position: relative;
    overflow: ${direction === 'row' ? 'hidden' : 'visible'};
    background-color: white;
    min-height: ${direction === 'row' ? '108px' : '0'};

    > div {
      flex: 1;
      padding: 5px;
    }

    h3 {
      margin-top: 0;
    }

    p {
      color: ${theme.colors.faded};
    }

    svg {
      font-size: ${direction === 'row' ? '30px' : '20px'};
    }

    img {
      border-radius: 0 3px 3px 0;
      width: 100px;
      height: 100px;
      margin-bottom: 0;
    }

    ${!color &&
      css`
        &:hover {
          border-color: #666;
        }
      `}

    ${direction === 'row' &&
      css`
        &:hover {
          &:after {
            background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.6) 50%,
              rgba(128, 186, 232, 0) 99%,
              rgba(125, 185, 232, 0) 100%
            ); /* W3C */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
            content: '';
            top: 0;
            transform: translateX(100%);
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            animation: ${slideAnimation} 0.5s linear forwards;
          }
        }
      `}

    ${checked &&
      css`
        border-color: ${color};
        box-shadow: 0px 0px 20px 3px #fff;
      `}
    ${empty &&
      css`
        min-height: 0 !important;
        height: 0 !important;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: 0;
        border: none;
      `}

    ${theme.breakpoint('tablet')`
      flex: 1 0 45%;
      min-height: ${direction === 'row' ? '108px' : '180px'};
    `}

    ${theme.breakpoint('desktop')`
      flex: 1 0 30%;
    `}
  `}
`;
