import React from 'react';

import { Button, Icon, Box, Container } from 'ui';
import { Translate } from 'react-i18n';

export default ({ children }: { children?: any }) => {
  const params: string[] = [`onSuccess=${process.env.REACT_APP_UI_ENDPOINT}/ok`, `onFailure=${process.env.REACT_APP_UI_ENDPOINT}/error`];

  return (
    <Container>
      <Box margin="large" textAlign="center">
        <Button
          primary
          as="a"
          fullWidth
          textTransform="none"
          color="google"
          href={`${process.env.REACT_APP_AUTH_ENDPOINT}/login/google?${params.join('&')}`}
        >
          <Icon name="google" />
          <span>
            <Translate id="register.with_google" defaultMessage="Register with Google" />
          </span>
        </Button>
      </Box>
    </Container>
  );
};
