import * as React from 'react';
import styled, { css } from 'styled-components';
import { Popup } from 'react-leaflet';

import { Flex, Button } from '../ui';

export interface IPopupProps {
  color?: string;
  inverseColor?: string;
  children: any;
}

const PopupStyled = styled(Popup)<IPopupProps>`
  ${({ color, inverseColor }) => css`
    border: 2px solid ${color};
    min-width: 250px;
    > div {
      border-radius: 0;
    }
    .leaflet-popup-tip {
      border: 2px solid ${color};
    }
    h4 {
      margin: -14px -20px 5px;
      padding: 10px 20px;
      background-color: ${color};
      color: ${inverseColor};
      span {
        display: block;
        font-size: 0.7em;
        font-weight: normal;
        font-style: italic;
      }
    }
  `}
`;

const PopupWrapper = ({ children, ...props }: IPopupProps) => <PopupStyled {...props}>{children}</PopupStyled>;

PopupWrapper.Link = styled('a')`
  display: block;
  margin: 10px -20px -14px -20px;
  text-align: right;
  padding: 4px 5px;
  background: #ececec;
  color: #999 !important;
  &:hover {
    color: #555 !important;
  }
`;

PopupWrapper.Address = styled('address')``;
PopupWrapper.Title = styled('h4')``;
PopupWrapper.Iframe = styled('iframe')`
  border: none;
  margin: 0 -20px;
`;
PopupWrapper.LogoList = styled(Flex).attrs({ marginHorizontal: 'xsmall', alignItems: 'center' })`
  margin: 5px 0;
  svg {
    font-weight: bold;
    font-size: 20px;
  }
`;

PopupWrapper.CTA = styled(Button).attrs({ primary: true, color: 'accent' })`
  color: white !important;
`;
export default PopupWrapper;
