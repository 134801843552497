import React from 'react';
import { withRouter } from 'react-router-dom';
import SEO from '../../app-components/SEO';
import { Translate } from '../../react-i18n';
const DetailsPage = ({ location, match }: any) => {
  const redirectUrl = decodeURIComponent(match.params.url);

  return (
    <>
      <SEO>
        <meta name="robots" content="noindex" />
        <meta http-equiv="refresh" content={`0;URL='${redirectUrl}'`} />
      </SEO>
      <div id="preloader">
        <img src="/assets/logo/remote-family.png" width="200" />
        <div
          style={{
            fontSize: '1.5em',
            margin: '10px auto 50px',
          }}
        >
          <Translate id="spots:redirect" defaultMessage="Please, wait while we redirect you to this place" />
        </div>
        <div id="loader" />
      </div>
    </>
  );
};

export default withRouter(DetailsPage);
