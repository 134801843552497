import * as React from 'react';
import styled from 'styled-components';
import { Mention } from 'react-twitter-widgets';
// import moment from 'moment';

import { Translate, useI18nContext, __ } from '../react-i18n';
import { Button, Flex, Modal, Box, Icon } from '../ui';
import useModal from '../hooks/useModal';
import MapButton from '../app-components/MapButton';

const Item = styled('a')`
  height: 150px;
  display: flex;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  img {
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.24), 0 1px 2px rgba(0, 0, 0, 0.48);
  }
`;

export default ({
  latitude,
  longitude,
  zoom,
  neLat,
  neLng,
  swLat,
  swLng,
}: {
  latitude: number;
  longitude: number;
  zoom: number;
  neLat: number;
  neLng: number;
  swLat: number;
  swLng: number;
}) => {
  const i18nContext = useI18nContext();
  const { modalOpened, showModal, hideModal } = useModal();

  // const checkInDate = moment()
  //   .add('30 days')
  //   .format('YYYY-MM-DD');
  // const checkOutDate = moment()
  //   .add('40 days')
  //   .format('YYYY-MM-DD');
  // const nbChildren = 2;
  // const nbAdults = 2;

  // href={`https://www.airbnb.com/s/homes?ne_lat=-8.682&ne_lng=115.18&sw_lat=-8.698&sw_lng=115.151&zoom=${zoom}&search_by_map=true&search_type=unknown&screen_size=large&map_toggle=true`}
  return (
    <>
      <MapButton primary size="xsmall" color="light" onClick={showModal}>
        <Icon name="hotel" />
        <Translate id="poi:search-more-modal:button" defaultMessage="Hotels and BnBs" />
      </MapButton>
      <Modal open={modalOpened} onClose={hideModal} size="small">
        <Modal.Content>
          <h2>
            <Translate id="poi:search-more-modal:title" defaultMessage="Awesome, you found your sweet spot!" />
          </h2>
          <Box marginVertical="medium">
            <Translate id="poi:search-more-modal:description1" defaultMessage="Now that you know where you go, you need a place to stay" />
          </Box>
          <Box marginVertical="medium">
            <Translate
              id="poi:search-more-modal:description2"
              defaultMessage="As we do not have yet the ability to integrate hotels directly within the map, here is a form to help you out"
            />
          </Box>
          <iframe
            title="travelpayouts"
            scrolling="yes"
            width="100%"
            height="250"
            frameBorder="0"
            src="//www.travelpayouts.com/widgets/40edecebcb6b1f03392671cb5e790e8c.html?v=1815"
          />
          <h3>
            <Translate
              id="poi:search-more-modal:help:title"
              defaultMessage="Hey, it would be better to have them directly on the map, don't you think?"
            />
          </h3>
          <Box marginVertical="medium">
            <Translate
              id="poi:search-more-modal:help"
              defaultMessage="We definitely agree. And in a (near) future, we will be able to integrate hotels and BnBs directly on the map and it will be awesome. But for this, we need access to their API and it will be much easier if you help us by reaching out to them on twitter"
            />
          </Box>
          <Flex marginHorizontal="medium" justifyContent="center">
            {/*<Flex.Item flex="1">
              <Mention
                username="travelpayouts"
                options={{
                  text: __(
                    {
                      id: 'poi:search-more-modal:travelpayouts:give',
                      defaultMessage:
                        'please, give @remote_family access to your API. The service they provide is really awesome for remote nomads and families, thanks',
                    },
                    i18nContext
                  ),
                }}
              />
            </Flex.Item>*/}
            <Flex.Item flex="1">
              <Mention
                username="airbnb"
                options={{
                  text: __(
                    {
                      id: 'poi:search-more-modal:airbnb:give',
                      defaultMessage:
                        'please, give @remote_family access to your API even if they do not reach 1M visitors per month. The service they provide is really awesome for remote nomads and families, thanks',
                    },
                    i18nContext
                  ),
                }}
              />
            </Flex.Item>
            <Flex.Item flex="1">
              <Mention
                username="bookingcom"
                options={{
                  text: __(
                    {
                      id: 'poi:search-more-modal:booking:give',
                      defaultMessage:
                        'please, give @remote_family access to your API. The service they provide is really awesome for remote nomads and families, thanks',
                    },
                    i18nContext
                  ),
                }}
              />
            </Flex.Item>
          </Flex>
          <Box marginVertical="medium">
            <Translate
              id="poi:search-more-modal:alternative"
              defaultMessage="While this feature is not available, you can already search on their website"
            />
          </Box>
          <Flex marginHorizontal="medium">
            {/*<Item
              href={`https://search.hotellook.com/hotels?latitude=${latitude}&longitude=${longitude}&checkIn=${checkInDate}&checkOut=${checkOutDate}&marker=247912&children=${nbChildren}&adults=${nbAdults}&language=${
                i18nContext.locale
              }#mds%3Dhotels_map`}
              target="_blank"
              rel="sponsored"
            >
              <img src="/assets/partners/hotellook-remote-family.jpg" alt="hotellook" width="100" height="100" />
            </Item>*/}
            <Item
              href={`https://www.airbnb.com/s/homes?ne_lat=${neLat}&ne_lng=${neLng}&sw_lat=${swLat}&sw_lng=${swLng}&zoom=${zoom}&search_by_map=true&search_type=unknown&map_toggle=true`}
              target="_blank"
              rel="sponsored"
            >
              <img src="/assets/partners/airbnb-remote-family.jpg" alt="airbnb" width="100" height="100" />
            </Item>
            <Item
              href={`https://www.booking.com/searchresults.html?latitude=${latitude};longitude=${longitude};#map_opened-map-header-cta`}
              target="_blank"
              rel="sponsored"
            >
              <img src="/assets/partners/booking-remote-family.jpg" alt="airbnb" width="100" height="100" />
            </Item>
          </Flex>

          {/*

          <Box marginHorizontal="small" marginVertical="large">
            <Translate
              id="poi:add-modal:help"
              defaultMessage="If you can give us the latitude, longitude, address and name or ideally a google maps link, that would be awesome"
            />
          </Box>
          <Box margin="small">
            <Translate id="poi:add-modal:mail-us" defaultMessage="Until you can enter all the data directly, please send us an email" />{' '}
            <a href="mailto:hello@remote-family.com">hello@remote-family.com</a>
          </Box>
          <Box margin="small">
            <Flex marginHorizontal="xsmall">
              <Translate id="poi:add-twitter:description" defaultMessage="Or alternatively you can" />
              <Mention
                username="remote_family"
                options={{
                  text: __(
                    {
                      id: 'poi:add-modal:feedback:twitter',
                      defaultMessage: 'Hey, here is a remote spot which should be included : ',
                    },
                    i18nContext
                  ),
                }}
              />
            </Flex>
          */}
        </Modal.Content>
        <Modal.Actions>
          <Button tertiary color="faded" size="small" onClick={hideModal}>
            <Translate id="poi:search-more-modal:cancel" defaultMessage="In fact, no thanks" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
