import { baseColors } from '../../theme/variables';

const ButtonTheme = {
  fontFamily: 'inherit',
  focus: {
    boxShadowSize: '0 0 0 0.125em',
  },
  disabled: {
    opacity: 0.65,
  },
  active: {
    backgroundColor: baseColors.whiteTer,
    borderColor: baseColors.whiteTer,
  },
  hover: {
    backgroundColor: baseColors.whiteTer,
    borderColor: baseColors.whiteTer,
  },
  sizes: {
    xxsmall: 0.75,
    xsmall: 0.8,
    small: 0.9,
    medium: 1,
    large: 1.25,
    xlarge: 1.5,
    xxlarge: 2,
  },
};

export default ButtonTheme;
