import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { capitalize, upperCase, lowerCase } from 'lodash/fp';

class Translate extends React.Component {
  renderAsString() {
    const {
      asString,
      intl: { formatMessage },
      textTransform,
      ...messageProps
    } = this.props;
    const { values, ...idAndMessage } = messageProps;
    let formattedMessage = formatMessage(idAndMessage, values);

    switch (textTransform) {
      case 'capitalize':
        formattedMessage = capitalize(formattedMessage);
        break;
      case 'uppercase':
        formattedMessage = upperCase(formattedMessage);
        break;
      case 'lowercase':
        formattedMessage = lowerCase(formattedMessage);
        break;
      default:
    }

    return formattedMessage;
  }

  render() {
    const { asString, intl, textTransform, ...messageProps } = this.props;

    if (asString) {
      return this.renderAsString();
    }

    if (textTransform) {
      return (
        <span style={{ textTransform }}>
          <FormattedMessage {...messageProps} />
        </span>
      );
    }

    // Maybe use https://github.com/yahoo/react-intl/blob/master/src/components/message.js
    return <FormattedMessage {...messageProps} />;
  }
}

Translate.propTypes = {
  intl: intlShape.isRequired,
  id: PropTypes.string.isRequired,
  asString: PropTypes.bool,
  textTransform: PropTypes.oneOf(['uppercase', 'lowercase', 'capitalize']),
  defaultMessage: PropTypes.string,
};

Translate.defaultProps = {
  asString: false,
  textTransform: null,
  defaultMessage: null,
};

export default injectIntl(Translate);
