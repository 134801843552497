import * as React from 'react';
import {
  MdAccountBalance,
  MdAdd,
  MdMenu,
  MdClose,
  MdRefresh,
  MdLink,
  MdList,
  MdPhoneInTalk,
  MdExpandLess,
  MdExpandMore,
  MdLocationCity,
  MdHotel,
  MdLocalAirport,
  MdLocalHospital,
  MdSwapHoriz,
} from 'react-icons/md';

import {
  FaFacebookF,
  FaTwitter,
  FaEnvelope,
  FaLinkedin,
  FaWhatsapp,
  FaGooglePlusG,
  FaGolfBall,
  FaGraduationCap,
  FaHiking,
  FaHorse,
  FaWater,
  FaFish,
  FaMountain,
  FaBuilding,
  FaSkating,
  FaSnowboarding,
  FaParachuteBox,
} from 'react-icons/fa';
import styled, { keyframes, css } from 'styled-components';

const MAPPING = {
  add: MdAdd,
  switchTimezones: MdSwapHoriz,
  // aiptrade: MdLaunch,
  // asideMenu: MdMoreVert,
  // certificate: MdFileDownload,
  // copy: MdContentCopy,
  // creditCard: MdCreditCard,
  // crown: IconCrown,
  // dashboard: MdDashboard,
  // edit: FaPen,
  // info: MdInfoOutline,
  // investedIn: MdDone,
  list: MdList,
  // onboardingNso: MdAccountBalance,
  // onboardingTrading: MdTrendingUp,
  // paginationLeft: MdKeyboardArrowLeft,
  // paginationRight: MdKeyboardArrowRight,
  // play: MdPlayArrow,
  // playVideo: MdOndemandVideo,
  // previewMusic: MdMusicNote,
  // promote: FaStar,
  // register: MdAssignment,
  // royalties: MdStar,
  // search: MdSearch,
  // share: MdShare,
  // stepperCheck: MdDone,
  // tiles: MdViewColumn,
  // timeline: MdTimeline,
  // trendingDown: MdTrendingDown,
  // trendingFlat: MdTrendingFlat,
  // trendingUp: MdTrendingUp,
  // upload: MdFileUpload,
  // user: MdPerson,
  airport: MdLocalAirport,
  burger: MdMenu,
  close: MdClose,
  loader: MdRefresh,
  phone: MdPhoneInTalk,
  website: MdLink,
  hotel: MdHotel,
  hospital: MdLocalHospital,
  up: MdExpandLess,
  down: MdExpandMore,

  // Categories
  'big-city': MdLocationCity,
  coworking: FaBuilding,
  golf: FaGolfBall,
  'golf-course': FaGolfBall,
  hiking: FaHiking,
  'mountain-biking': FaMountain,
  'international-school': FaGraduationCap,
  surf: FaWater,
  kitesurf: FaParachuteBox,
  'horse-riding': FaHorse,
  'scuba-diving': FaFish,
  skateboard: FaSnowboarding,
  'ice-rink': FaSkating,
  'world-heritage-unesco': MdAccountBalance,

  // social icons because the ones from semantic-ui are not well centered
  email: FaEnvelope,
  google: FaGooglePlusG,
  facebook: FaFacebookF,
  linkedin: FaLinkedin,
  twitter: FaTwitter,
  whatsapp: FaWhatsapp,
};

export const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const makeSpinnable = (Icon: any) => styled(Icon)`
  ${({ spin }) =>
    spin &&
    css`
      animation: ${spinAnimation} 1s infinite linear;
    `}
`;

export type TIconName =
  | 'add'
  | 'airport'
  | 'big-city'
  | 'burger'
  | 'close'
  | 'coworking'
  | 'down'
  | 'email'
  | 'facebook'
  | 'golf-course'
  | 'golf'
  | 'google'
  | 'hiking'
  | 'horse-riding'
  | 'hospital'
  | 'hotel'
  | 'ice-rink'
  | 'international-school'
  | 'kitesurf'
  | 'linkedin'
  | 'list'
  | 'loader'
  | 'mountain-biking'
  | 'phone'
  | 'scuba-diving'
  | 'skateboard'
  | 'surf'
  | 'switchTimezones'
  | 'twitter'
  | 'up'
  | 'website'
  | 'whatsapp'
  | 'world-heritage-unesco';

export interface IProps {
  name: TIconName;
  spin?: boolean;
  [key: string]: any;
}

export default ({ name, ...rest }: IProps) => {
  const DefinedIcon: any = makeSpinnable(MAPPING[name]);
  return <DefinedIcon {...rest} />;
};
