import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import * as fp from 'lodash/fp';
import { Mention } from 'react-twitter-widgets';

import { Translate, useI18nContext, __ } from '../react-i18n';
import { Button, Flex, Modal, Badge, Loader } from '../ui';
import useArray from '../hooks/useArray';
import useModal from '../hooks/useModal';
import CategoryRequestForm from '../app-components/Form/CategoryRequestForm';
import CategoryRequests from '../app-components/CategoryRequests';
import CategoryList from './CategoryList';

const GET_CATEGORIES = gql`
  {
    categories: listCategories(orderBy: "name:asc") {
      count
      nodes {
        _id
        name
        description
        image
        website
        slug
        icon
        color
        inverseColor
        shape
        type
      }
    }
  }
`;

interface IProps {
  defaultCategories: string;
  onChange: (values: any[]) => any;
  onLoad: (values: any[]) => any;
  badges?: boolean;
  type?: 'passion' | 'need';
}

export default ({ defaultCategories, onChange, onLoad, badges, type, ...rest }: IProps) => {
  const { data, loading } = useQuery(GET_CATEGORIES);
  const i18nContext = useI18nContext();
  const categories = fp.get('categories.nodes')(data) || [];
  const [loaded, setLoaded] = useState(false);
  const { modalOpened, showModal, hideModal } = useModal();
  const [categoryChanged, setCategoryChanged] = useState(0); // this is used to detect the change ONLY on checkbox update and not on initial load
  const [requestCategory, setRequestedCategory] = useState('');
  const chosenCategories = useArray([]);

  const setCategory = ({ name, value }: any) => {
    if (value) {
      chosenCategories.add(categories.find((category: any) => category._id === name));
    } else {
      chosenCategories.removeById(name);
    }
  };

  const updateCategories = (e: any, { name, value }: any) => {
    setCategory({ name, value });
    setCategoryChanged(categoryChanged + 1);
  };

  const toggleCategory = (categoryId: string) => (e: any) => {
    setCategory({ name: categoryId, value: !chosenCategories.has(categoryId) });
    setCategoryChanged(categoryChanged + 1);
  };

  useEffect(() => {
    const pickedCategories: any[] = [];

    if (!defaultCategories) {
      setLoaded(true);
      return;
    }

    if (categories.length > 0 && defaultCategories) {
      defaultCategories.split(',').forEach((categoryName: string) => {
        const category = categories.find((c: any) => c.slug === categoryName);
        if (category) {
          if (!loaded || !type || category.type === type) {
            setCategory({ name: category._id, value: true });
            pickedCategories.push(category);
          }
        }
      });
      onLoad(pickedCategories);
      setLoaded(true);
    }
  }, [categories]);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    onChange(chosenCategories.value);
  }, [categoryChanged]);

  if (badges) {
    return (
      <>
        {loading && <Loader width={30} height={30} />}
        {chosenCategories.value.map((chosenCategory: any) => (
          <Badge customColor={chosenCategory.color} key={chosenCategory._id}>
            {chosenCategory.name}
          </Badge>
        ))}
      </>
    );
  }
  return (
    <div {...rest}>
      {loading && <Loader />}
      <CategoryList
        categories={categories.filter((c: any) => c.type === type)}
        loading={loading}
        isChecked={chosenCategories.has}
        onClick={toggleCategory}
        onChange={updateCategories}
        onNeedMore={showModal}
        type={type}
      />

      <Modal open={modalOpened} onClose={hideModal} size="small">
        <Modal.Content>
          <h2>
            <Translate id="categories.notmine.modal.title" defaultMessage="Thank you so much for your interest" />
          </h2>
          <p>
            <Translate
              id="categories.notmine.modal.description"
              defaultMessage="We're still a pretty young service, and we want to help digital nomads, remote families and more find their sweet spot, so thank you for your implication"
            />
          </p>
          <CategoryRequestForm category={requestCategory} onSuccess={hideModal} />
          <Flex marginHorizontal="xsmall">
            <Translate id="categories.notmine.twitter.description" defaultMessage="Or alternatively you can" />
            <Mention
              username="remote_family"
              options={{
                text: __(
                  { id: 'feedback:category:twitter', defaultMessage: 'Hey, let me find all places in the world where I can work remote and do ...' },
                  i18nContext
                ),
              }}
            />
          </Flex>
          <h3>
            <Translate id="categories.requests.modal.title" defaultMessage="Check all the already asked needs" />
          </h3>
          <CategoryRequests onClick={setRequestedCategory} />
        </Modal.Content>
        <Modal.Actions>
          <Button tertiary color="faded" size="small" onClick={hideModal}>
            <Translate id="categories.notmine.modal.cancel" defaultMessage="In fact, no thanks" />
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};
