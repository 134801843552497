import * as React from 'react';
import styled from 'styled-components';

const component: <Props>(Tag: any) => React.SFC<Props> = (Tag: any) => (props: any) => <Tag {...props}>{props.children}</Tag>;

const FullPageLayout = styled<any>('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: stretch;
`;

interface AsProps {
  as?: any;
}

const Header = styled(component<AsProps>(({ as = 'header', ...props }: AsProps) => React.createElement(as, props)))``;

const Main = styled(component<AsProps>(({ as = 'main', ...props }: AsProps) => React.createElement(as, props)))`
  flex: 1;
  display: flex;
  align-items: stretch;
  > * {
    flex: 1;
  }
`;

const Footer = styled(component<AsProps>(({ as = 'footer', ...props }: AsProps) => React.createElement(as, props)))``;

const FullPageLayoutWrapper = (props: { children: any }) => <FullPageLayout>{props.children}</FullPageLayout>;

FullPageLayoutWrapper.Header = Header;
FullPageLayoutWrapper.Main = Main;
FullPageLayoutWrapper.Footer = Footer;

export default FullPageLayoutWrapper;
