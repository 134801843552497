// import * as React from 'react';
// import { Form } from 'semantic-ui-react';
import Select from 'react-select';

export default Select;

// interface IOption {
//   value: string;
//   label: string;
// }
//
// interface ISelectExtended {
//   label?: string;
//   onChange: (e?: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>, data?: any) => void;
//   name: string;
//   options: IOption[];
// }
//
// const onEntitySelectChange = (
//   name: string,
//   onChange: (e?: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>, data?: any) => void
// ) => (data: string, actionMeta: any) => {
//   console.log(''); // eslint-disable-line
//   console.log('╔════START══data══════════════════════════════════════════════════'); // eslint-disable-line
//   console.log(data); // eslint-disable-line
//   console.log('╚════END════data══════════════════════════════════════════════════'); // eslint-disable-line
//
//   return onChange(undefined, { name, value: data });
// };
//
// export default ({ label, onChange, name, options, ...rest }: ISelectExtended & Select) => (
//   <Form.Field>
//     {label && <label htmlFor={name}>{label}</label>}
//     <Select onInputChange={onEntitySelectChange(name, onChange)} options={options} {...rest} />
//   </Form.Field>
// );
