import React from 'react';
import styled, { css } from 'styled-components';
import { getInvertColor } from '../../theme/style-utils';

interface IProps {
  color?: string;
  bg?: string;
  customColor?: string;
  customBgColor?: string;
}

const Color = styled(({ color, customColor, customBgColor, bg, ...props }) => React.createElement('span', props))<IProps>`
  ${({ theme, color, customColor, customBgColor, bg }) => css`
    /* Generated colors */
    ${Object.keys(theme.colors).map(
      (colorKey: string) => css`
        ${color === colorKey &&
          css`
            color: ${theme.colors[colorKey]};
          `}
        ${bg === colorKey &&
          css`
            background: ${theme.colors[colorKey]};
            color: ${getInvertColor(theme.colors[colorKey])};
          `}
      `
    )};
    ${customColor &&
      css`
        color: ${customColor};
      `}
    ${customBgColor &&
      css`
        background: ${customBgColor};
        color: ${getInvertColor(customBgColor)};
      `}
  `}
`;

export default Color;
