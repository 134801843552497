import * as React from 'react';
// import styled from 'styled-components';
import { Flex, Button } from '../../../ui';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from 'react-share';
import { MdShare as ShareIcon } from 'react-icons/md';

// const WeiboIcon = styled('img').attrs({
//   src: 'http://icons.iconarchive.com/icons/martz90/circle-addon2/512/weibo-icon.png',
//   alt: 'Weibo share button',
// })`
//   width: 30px;
//   margin-bottom: -5px;
// `;

const share = ({ title, url }: ShareData) => () =>
  navigator &&
  navigator.share &&
  navigator.share({
    title,
    url,
  });

export default ({ url, title }: { url: string; title: string }) => {
  if (navigator && navigator.share) {
    return (
      <Button primary color="accent" onClick={share({ url, title })}>
        <ShareIcon />
      </Button>
    );
  }

  return (
    <Flex alignItems="center" wrap="wrap" marginHorizontal="2px">
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={30} round />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={30} round />
      </TwitterShareButton>

      {/*<TelegramShareButton url={url} title={title}>
        <TelegramIcon size={30} round />
      </TelegramShareButton>*/}

      <WhatsappShareButton url={url} title={title} separator=":: ">
        <WhatsappIcon size={30} round />
      </WhatsappShareButton>

      <LinkedinShareButton url={url}>
        <LinkedinIcon size={30} round />
      </LinkedinShareButton>
      {/*
      <LineShareButton url={url} title={title}>
        <LineIcon size={30} round />
      </LineShareButton>

      <WeiboShareButton url={url} title={title}>
        <WeiboIcon />
      </WeiboShareButton>
      */}
    </Flex>
  );
};
