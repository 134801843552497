import React from 'react';
import PropTypes from 'prop-types';
import { FormattedDate } from 'react-intl';
import mo from 'moment';

class TranslateDate extends React.Component {
  render() {
    const { value, format, day, month, year, hour, minute, second, moment } = this.props;
    if (!value) {
      return null;
    }
    let formatProps = {};
    let defaultProps = false;
    if (!value) {
      return null;
    }
    if (!format && !day && !month && !year && !hour && !minute && !second) {
      defaultProps = true;
    }

    if (moment) {
      // TODO make this work when you change locale
      return typeof moment === 'string' ? mo(value)[moment]() : mo(value).format(format);
    }

    if (format === 'lll' || defaultProps) {
      formatProps = {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    } else if (format === 'LTS') {
      formatProps = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      };
    } else if (format === 'll') {
      formatProps = {
        day: 'numeric',
        month: 'short',
        year: '2-digit',
      };
    } else {
      if (day) formatProps.day = day;
      if (month) formatProps.month = month;
      if (year) formatProps.year = year;
      if (hour) formatProps.hour = hour;
      if (minute) formatProps.minute = minute;
      if (second) formatProps.second = second;
    }

    return <FormattedDate value={value} {...formatProps} />;
  }
}

TranslateDate.propTypes = {
  value: PropTypes.string,
  format: PropTypes.oneOf(['lll', 'LTS', 'll']),
  weekday: PropTypes.oneOf(['narrow', 'short', 'long']),
  day: PropTypes.oneOf(['numeric', '2-digit']),
  month: PropTypes.oneOf(['numeric', '2-digit', 'narrow', 'short', 'long']),
  year: PropTypes.oneOf(['numeric', '2-digit']),
  hour: PropTypes.oneOf(['numeric', '2-digit']),
  minute: PropTypes.oneOf(['numeric', '2-digit']),
  second: PropTypes.oneOf(['numeric', '2-digit']),
  moment: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['calendar', 'fromNow'])]),
};

TranslateDate.defaultProps = {
  format: null,
  weekday: null,
  day: null,
  month: null,
  year: null,
  hour: null,
  minute: null,
  second: null,
  moment: false,
};

TranslateDate.contextTypes = {
  locale: PropTypes.string,
};

export default TranslateDate;
