import React from 'react';
import { useI18nContext } from '../../react-i18n';
import { __ } from '../../react-i18n';
import SEO from '../../app-components/SEO';

export default ({ categories = [], languages = [] }: { categories: any[]; languages: any[] }) => {
  const i18nContext = useI18nContext();

  let languageNames = languages.reduce((acc: string[], val: any) => [...acc, val.name], []).join(', ');
  let categoryNames = categories.reduce((acc: string[], val: any) => [...acc, val.name], []).join(', ');

  languageNames = languageNames.replace(/,([^,]*)$/, ` ${__({ id: 'and', defaultMessage: 'and' }, i18nContext)} $1`);
  categoryNames = categoryNames.replace(/,([^,]*)$/, ` ${__({ id: 'and', defaultMessage: 'and' }, i18nContext)} $1`);

  let title = __({ id: 'seo.default.title', defaultMessage: 'Map of best remote places in the world for your passions' }, i18nContext);
  let description = __(
    {
      id: 'seo.default.description',
      defaultMessage:
        'Decide where you want to live and enjoy yourself! Do you have hobbies, do you travel with your family? Discover the most fantastic remote places that combine your passions',
    },
    i18nContext
  );

  if (categoryNames && !languageNames) {
    title = __(
      {
        id: 'seo.category.title',
        defaultMessage: 'Map of remote {categoryNames} places',
        values: {
          categoryNames,
        },
      },
      i18nContext
    );
    description = __(
      {
        id: 'seo.category.description',
        defaultMessage: 'Discover all places in the world where you can work remote with your family and enjoy {categoryNames}',
        values: {
          categoryNames,
        },
      },
      i18nContext
    );
  }
  if (categoryNames && languageNames) {
    title = __(
      {
        id: 'seo.category-language.title',
        defaultMessage: 'Map of remote {categoryNames} places where you speak {languageNames}',
        values: {
          languageNames,
          categoryNames,
        },
      },
      i18nContext
    );
    description = __(
      {
        id: 'seo.category-language.description',
        defaultMessage:
          'Discover all places in the world where you can work remote with your family and enjoy {categoryNames} while also learning to speak {languageNames}',
        values: {
          languageNames,
          categoryNames,
        },
      },
      i18nContext
    );
  }

  return <SEO title={title} description={description} />;
};
