import * as React from 'react';
import MainLayout, { Context as MainLayoutContext } from './MainLayout';
import TwoColumnCollapsible, { Context as TwoColumnCollapsibleContext } from '../containers/TwoColumnCollapsible';

const SpotLayoutWrapper = (props: { children: any }) => (
  <MainLayout active="spot">
    <TwoColumnCollapsible>{props.children}</TwoColumnCollapsible>
  </MainLayout>
);

SpotLayoutWrapper.Main = TwoColumnCollapsible.Main;
SpotLayoutWrapper.Aside = TwoColumnCollapsible.Aside;

export const SpotLayoutContext = TwoColumnCollapsibleContext;
export const SpotLayoutMainContext = MainLayoutContext;

export default SpotLayoutWrapper;
