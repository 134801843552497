import React, { useState } from 'react';
import { Slider } from 'react-semantic-ui-range';
import styled from 'styled-components';
import memoize from 'lodash/fp/memoize';

import { Flex, Badge, Popup, Button, Modal, Icon } from '../ui';
import CountryDataTable from './CountryDataTable';

const Wrapper = styled('div')`
  width: 250px;
  .semantic_ui_range_inner > div:nth-child(2) {
    background: green;
  }
`;

interface IProps {
  defaultCostOfLife: number[];
  onChange: (values: any[]) => any;
  onLoad: (values: any[]) => any;
  badges?: boolean;
  countries?: any[];
}

const MIN = 12;
const MAX = 143;

const range = (start: number, end: number) => Array.from({ length: end - start }, (v, k) => k + start);

const getCostOfLifeArray = memoize((countries: any) => {
  const rangeCostOfLife = range(MIN, MAX + 1);
  const existingCostOfLifes: any = {};
  const finalCostOfLifes: any = {};

  countries
    .filter((country: any) => !!country.costOfLife)
    .forEach((val: any) => {
      const floor = Math.floor(val.costOfLife);
      const ceil = Math.ceil(val.costOfLife);
      const chosenCostOfLife = val.costOfLife % 1 >= 0.5 ? ceil : floor;
      const value = `${val.name}, ${val.costOfLife.toFixed(2)}`;

      if (existingCostOfLifes[chosenCostOfLife]) {
        existingCostOfLifes[chosenCostOfLife].push(value);
      } else {
        existingCostOfLifes[chosenCostOfLife] = [value];
      }
    });

  let lastCostOfLife = null;

  for (const temp of rangeCostOfLife) {
    const costOfLife = rangeCostOfLife[temp];
    finalCostOfLifes[costOfLife] = existingCostOfLifes[costOfLife] || lastCostOfLife;
    lastCostOfLife = finalCostOfLifes[costOfLife];
  }

  return finalCostOfLifes;
});

export default ({ defaultCostOfLife, onChange, onLoad, badges, countries, ...rest }: IProps) => {
  const [min, setMin] = useState(defaultCostOfLife[0] || MIN);
  const [max, setMax] = useState(defaultCostOfLife[1] || MAX);

  if (badges) {
    if (min === MIN && max === MAX) {
      return null;
    }

    return (
      <Badge secondary customColor="green" key="costOfLife_min">
        {min} &lt; 💰&lt; {max}
      </Badge>
    );
  }

  const costOfLifes = getCostOfLifeArray(countries);
  const minCostOfLifes = costOfLifes[min] || [];
  const maxCostOfLifes = costOfLifes[max] || [];

  const settings = {
    start: [min, max],
    min: MIN,
    max: MAX,
    step: 1,
    onChange: ([newMin, newMax]: any) => {
      setMin(newMin);
      setMax(newMax);
      onChange([newMin, newMax]);
    },
  };

  return (
    <Wrapper>
      <Slider multiple color="green" settings={settings} />
      <Flex alignItems="space-between">
        <Flex.Item flex="1" style={{ textAlign: 'left' }}>
          {min}
        </Flex.Item>
        <Flex.Item flex="1" style={{ textAlign: 'right' }}>
          {max}
        </Flex.Item>
      </Flex>
      <Flex alignItems="space-between">
        <Flex.Item flex="1" style={{ textAlign: 'left' }}>
          {minCostOfLifes.length > 0 && (
            <Popup trigger={<em>{minCostOfLifes[0].split(',')[0]}</em>} inverted position="bottom left">
              <Popup.Content>
                {minCostOfLifes.map((t: string) => (
                  <div key={t}>{t}</div>
                ))}
              </Popup.Content>
            </Popup>
          )}
        </Flex.Item>
        <Flex.Item>
          <Modal
            trigger={
              <Button secondary color="faded" size="xsmall">
                <Icon name="list" />
              </Button>
            }
          >
            <Modal.Content>
              <CountryDataTable countries={countries} fields={['name', 'costOfLife']} />
            </Modal.Content>
          </Modal>
        </Flex.Item>
        <Flex.Item flex="1" style={{ textAlign: 'right' }}>
          {maxCostOfLifes.length > 0 && (
            <Popup trigger={<em>{maxCostOfLifes[0].split(',')[0]}</em>} inverted position="bottom right">
              <Popup.Content>
                {maxCostOfLifes.map((t: string) => (
                  <div key={t}>{t}</div>
                ))}
              </Popup.Content>
            </Popup>
          )}
        </Flex.Item>
      </Flex>
    </Wrapper>
  );
};
