import React, { useState, createContext } from 'react';
import styled, { css } from 'styled-components';

import FullPageLayout from '../containers/FullPageLayout';
import Footer from '../app-components/Footer';
import Navigation from '../app-components/Navigation';
import { Flex, Logo, Marquee, Boxed } from '../ui';

const Header = styled('div')`
  background: #fff;
  height: 58px;
  margin: 1px 0;
`;

const FooterStyled = styled.footer`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background: ${theme.backgroundColors.dark};
    height: 60px;
  `}
`;

const LogoLink = styled.a`
  margin-left: 26px;
  margin-right: 12px;
  img {
    margin-bottom: 0;
  }
`;

const Title = styled.h1`
  ${({ theme }) => css`
    background-color: ${theme.backgroundColors.dark};
    color: white;
    font-size: 13px;
    font-weight: normal;
    padding: 5px;
    margin: 0;
    font-family: Lato !important;
  `}
`;

interface IProps {
  children: any;
  active?: 'spot';
}

export const Context = createContext<{ setTitle?: any }>({});

export default ({ children, active }: IProps) => {
  const [title, setTitle] = useState<any>(null);
  return (
    <Context.Provider value={{ setTitle }}>
      <FullPageLayout>
        <FullPageLayout.Header as={Header}>
          <Boxed>
            <Flex>
              <Flex.Item as={Flex} alignItems="center">
                <LogoLink href="/spot">
                  <Logo />
                </LogoLink>
              </Flex.Item>
              <Flex.Item grow="1" as={Flex} alignItems="stretch">
                <Navigation active={active} />
              </Flex.Item>
            </Flex>
          </Boxed>
        </FullPageLayout.Header>
        {title && (
          <Title>
            <Marquee>{title}</Marquee>
          </Title>
        )}
        <FullPageLayout.Main>{children}</FullPageLayout.Main>
        <FullPageLayout.Footer as={FooterStyled}>
          <Footer />
        </FullPageLayout.Footer>
      </FullPageLayout>
    </Context.Provider>
  );
};
