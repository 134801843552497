import React from 'react';
import { Route } from 'react-router-dom';

import Spot from './Spot';
import Details from './Details';

const Routes = [
  <Route exact key="spot_detail" path="/spot/details/:categoryId/:id/:url" component={Details} public />,
  <Route exact key="spot_geohash_languages" path="/spot/:categories/:languages/:geohash/:countries/:bounds" component={Spot} public />,
  <Route exact key="spot_geohash" path="/spot/:categories/:geohash/:countries/:bounds" component={Spot} public />,
  <Route exact key="spot_categories_languages" path="/spot/:categories/:languages" component={Spot} public />,
  <Route exact key="spot_categories" path="/spot/:categories" component={Spot} public />,
  <Route exact key="spot" path="/spot/" component={Spot} />,
];

export default Routes;
