import * as React from 'react';
import { capitalize, get } from 'lodash/fp';

import { Box, Button, Icon, Number } from '../ui';
import { Translate } from '../react-i18n';
import MarkerCategory from '../app-components/MarkerCategory';
import Popup from '../app-components/Popup';
import { formatPosition } from '../utils/geo';
// import { IPoi } from '../types';

const getDetailUrl = (poi: any, url: string) => {
  const uri = poi.category.icon;
  const id = poi._id;
  return `/spot/details/${uri}/${id}/${encodeURIComponent(url)}`;
};

interface IProps {
  pois: any[];
  onClick: (e: any) => any;
  onRemove: (poiId: string) => void;
}

export default React.memo(({ pois, onClick, onRemove }: IProps) => {
  const handleRemove = (poiId: string) => () => onRemove(poiId);
  return (
    <>
      {pois.map((poi: any) => (
        <MarkerCategory
          key={poi._id}
          position={formatPosition(poi.location.coordinates)}
          icon={poi.category && poi.category.icon}
          color={poi.category && poi.category.color}
          inverseColor={poi.category && poi.category.inverseColor}
          shape={poi.category && poi.category.shape}
          onclick={onClick}
        >
          <Popup color={poi.category && poi.category.color} inverseColor={poi.category && poi.category.inverseColor}>
            <Popup.Title>
              {capitalize(poi.name)}
              {poi.altName && <span>{poi.altName}</span>}
            </Popup.Title>
            <Popup.Address>{poi.address}</Popup.Address>
            <hr />
            {poi.website && (
              <Popup.LogoList>
                <Icon name="website" />
                <a href={poi.website} target="_blank" rel="noopener noreferrer">
                  {poi.website}
                </a>
              </Popup.LogoList>
            )}
            {poi.phone && (
              <Popup.LogoList>
                <Icon name="phone" />
                <a href={`tel://${poi.phone}`}>{poi.phone}</a>
              </Popup.LogoList>
            )}
            {(get('fieldworkeducation.url')(poi) || get('cambridgeinternational.url')(poi) || get('cobis.url')(poi)) && (
              <>
                <br />
                <Translate id="marker:fieldworkeducation:curriculums" defaultMessage="This school proposes the following curriculums" />
                <ul>
                  {get('fieldworkeducation.ieyc')(poi) && (
                    <li>
                      <a href="https://fieldworkeducation.com/curriculums/early-years" rel="noopener noreferrer" target="_blank">
                        IEYC (International Early Years Curriculum)
                      </a>
                    </li>
                  )}
                  {get('fieldworkeducation.ipc')(poi) && (
                    <li>
                      <a href="https://fieldworkeducation.com/curriculums/primary-years" rel="noopener noreferrer" target="_blank">
                        IPC (International Primary Curriculum)
                      </a>
                    </li>
                  )}
                  {get('fieldworkeducation.imyc')(poi) && (
                    <li>
                      <a href="https://fieldworkeducation.com/curriculums/middle-years" rel="noopener noreferrer" target="_blank">
                        IMYC (International Middle Years Curriculum)
                      </a>
                    </li>
                  )}
                  {get('cambridgeinternational')(poi) && (
                    <li>
                      Cambridge (
                      <a
                        href="https://www.cambridgeinternational.org/exam-administration/private-candidates/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Translate id="international-school: accept-private-candidates" defaultMessage="Accept private candidates" />
                      </a>{' '}
                      : {get('cambridgeinternational')(poi) ? <Translate id="yes" defaultMessage="yes" /> : <Translate id="no" defaultMessage="no" />}
                      )
                    </li>
                  )}
                  {get('cobis')(poi) && (
                    <li>
                      <a href="https://www.cobis.org.uk/" rel="noopener noreferrer" target="_blank">
                        Council Of British International School (COBIS)
                      </a>
                    </li>
                  )}
                  {get('cobis.schoolType')(poi) && <li>School Type: {get('cobis.schoolType')(poi)}</li>}
                  {get('cobis.image')(poi) && (
                    <li>
                      <img src={get('cobis.image')(poi)} width="200" alt="COBIS" />
                    </li>
                  )}
                </ul>
              </>
            )}
            {get('citypopulation.population')(poi) && (
              <>
                <br />
                <ul>
                  <li>
                    <Translate
                      id="place:big-city:inhabitants"
                      defaultMessage="{nb} inhabitants"
                      values={{ nb: <Number value={get('citypopulation.population')(poi)} /> }}
                    />
                  </li>
                </ul>
              </>
            )}
            {get('worldheritagelist')(poi) && (
              <>
                <br />
                <ul>
                  <li>
                    <Translate
                      id="place:world-heritage.inscriptionDate"
                      defaultMessage="Inscription date: {date}"
                      values={{ date: get('worldheritagelist.dateInscription')(poi) }}
                    />
                  </li>
                  {get('worldheritagelist.danger')(poi) && (
                    <li>
                      ⚠️
                      <Translate id="place:world-heritage.danger" defaultMessage="This site is in Danger" />
                    </li>
                  )}
                </ul>
              </>
            )}
            {get('padi.features')(poi) && (
              <>
                <br />
                <ul>
                  {get('padi.features')(poi).map((feature: string) => (
                    <li key={`padi_${poi._id}_feature`}>{feature}</li>
                  ))}
                </ul>
              </>
            )}
            {get('windguru._id')(poi) && <Popup.Iframe src={`${process.env.REACT_APP_UI_ENDPOINT}/windguru.html?id=${poi.windguru._id}`} />}
            {(poi.googlePlaceId || poi.windguru) && !poi.verified && (
              <>
                <hr />
                <Box textAlign="right">
                  <Button onClick={handleRemove(poi._id)} tertiary color="negative" size="xsmall">
                    <Translate id="place.wrong.action" defaultMessage="Report as wrong" />
                  </Button>
                </Box>
              </>
            )}
            {/* bottom links */}
            {!poi.website && poi.googlePlaceId && (
              <Popup.Link
                href={getDetailUrl(poi, `https://www.google.com/maps/place/?q=place_id:${poi.googlePlaceId}`)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'Google Maps' }} />
              </Popup.Link>
            )}
            {get('shakabay.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, get('shakabay.url')(poi))} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'Shakabay.com' }} />
              </Popup.Link>
            )}
            {get('windguru.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.windguru.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'Windguru.cz' }} />
              </Popup.Link>
            )}
            {get('leadingcourses.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.leadingcourses.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'LeadingCourses.com' }} />
              </Popup.Link>
            )}
            {get('coworker.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.coworker.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'Coworker.com' }} />
              </Popup.Link>
            )}
            {get('rinkresults.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.rinkresults.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'rinkresults.com' }} />
              </Popup.Link>
            )}
            {get('fieldworkeducation.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.fieldworkeducation.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'fieldworkeducation.com' }} />
              </Popup.Link>
            )}
            {get('cobis.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.cobis.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'cobis.org.uk' }} />
              </Popup.Link>
            )}
            {get('cambridgeinternational.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.cambridgeinternational.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'cambridgeinternational.com' }} />
              </Popup.Link>
            )}
            {get('citypopulation.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.citypopulation.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'citypopulation.de' }} />
              </Popup.Link>
            )}
            {get('alltrails.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.alltrails.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'alltrails.com' }} />
              </Popup.Link>
            )}
            {get('padi.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.padi.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'locator.padi.com' }} />
              </Popup.Link>
            )}
            {get('worldheritagelist.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.worldheritagelist.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'whc.unesco.org' }} />
              </Popup.Link>
            )}
            {get('skateboard.url')(poi) && (
              <Popup.Link href={getDetailUrl(poi, poi.skateboard.url)} target="_blank" rel="noopener noreferrer">
                <Translate id="see.on" defaultMessage="See on {name}" values={{ name: 'skateboard.com.au' }} />
              </Popup.Link>
            )}
          </Popup>
        </MarkerCategory>
      ))}
    </>
  );
});
