import React, { useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { get } from 'lodash/fp';
import { Map, TileLayer, ZoomControl, Circle, ScaleControl, LayersControl, Popup, FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';

import useLocalStorage from '../../../hooks/useLocalStorage';
import TwoColumnCollapsible from '../../../containers/TwoColumnCollapsible';
import SEO from '../../../app-components/SEO';

const { BaseLayer } = LayersControl;
const mapStyle: any = { position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 1 };

const GET_LATLNGS = gql`
  query worldMapCircles($bounds: [[Float]]) {
    circles: worldMapCircles(bounds: $bounds) {
      count
      limit
      nodes {
        _id
        name
        status
        location {
          type
          coordinates
        }
        radius
      }
    }
    nbOk: worldMapCircles(status: OK) {
      count
    }
    nbEmpty: worldMapCircles(status: EMPTY) {
      count
    }
  }
`;

const UPDATE_STATUS = gql`
  mutation updateWorldMapCircleStatus($id: ID, $status: WorldMapCircleStatus) {
    updateWorldMapCircleStatus(_id: $id, status: $status) {
      message {
        text
        code
      }
      result {
        _id
        name
        status
      }
    }
  }
`;
const UPDATE_STATUS_IN_POLYGON = gql`
  mutation updateWorldMapCircleStatusInPolygon($polygon: PolygonFeature, $status: WorldMapCircleStatus) {
    updateWorldMapCircleStatusInPolygon(polygon: $polygon, status: $status) {
      message {
        text
        code
      }
      result
    }
  }
`;

export default ({ children }: { children?: any }) => {
  const [bounds, setBounds] = useLocalStorage('geohash_bounds', [[0, 0], [1, 1]]);
  const leafletMap = useRef(null);
  const { data, refetch } = useQuery(GET_LATLNGS, { variables: { bounds } });
  const [updateWorldMapCircleStatus] = useMutation(UPDATE_STATUS);
  const [updateWorldMapCircleStatusInPolygon] = useMutation(UPDATE_STATUS_IN_POLYGON);

  const circles = get('circles.nodes')(data) || [];

  const handleEvent = async () => {
    const leafletElement = get('current.leafletElement')(leafletMap);
    if (leafletElement) {
      const {
        _southWest: { lat: latSW, lng: lngSW },
        _northEast: { lat: latNE, lng: lngNE },
      } = leafletElement.getBounds();

      const newBounds: any[] = [[lngSW, latSW], [lngSW, latNE], [lngNE, latNE], [lngNE, latSW], [lngSW, latSW]];
      setBounds([[latSW, lngSW], [latNE, lngSW], [latNE, lngNE], [latSW, lngNE], [latSW, lngSW]]);
      await refetch({ bounds: newBounds });
    }
  };

  const onClick = ({ _id, status }: any) => async (e: any) => {
    await updateWorldMapCircleStatus({ variables: { id: _id, status: status === 'OK' ? 'EMPTY' : 'OK' } });
    // TODO update fragment instead
    handleEvent();
  };

  // const setAll = (status: string) => async () => {
  //   // @ts-ignore
  //   if (window.confirm('sure?')) {
  //     await Promise.all(
  //       circles.map(async (circle: any) => {
  //         await updateWorldMapCircleStatus({ variables: { id: circle._id, status } });
  //       })
  //     );
  //     handleEvent();
  //   }
  // };

  const setAllInside = async (e: any) => {
    const layer = e.layer;

    const shape = layer.toGeoJSON();
    await updateWorldMapCircleStatusInPolygon({ variables: { polygon: shape, status: 'EMPTY' } });
    handleEvent();
  };

  return (
    <TwoColumnCollapsible>
      <SEO link={[{ href: '//cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.3/leaflet.draw.css', rel: 'stylesheet' }]} />
      <TwoColumnCollapsible.Aside fullWidth={false}>
        <table>
          <tbody>
            <tr>
              <td>Nb circles</td>
              <td>{circles.length}</td>
            </tr>
            <tr>
              <td>Nb circles with something in</td>
              <td>{get('nbOk.count')(data)}</td>
            </tr>
            <tr>
              <td>Nb circles with nothing in</td>
              <td>{get('nbEmpty.count')(data)}</td>
            </tr>
          </tbody>
        </table>
      </TwoColumnCollapsible.Aside>
      <TwoColumnCollapsible.Main>
        <Map zoomControl={false} style={mapStyle} ref={leafletMap} bounds={bounds} onMoveend={handleEvent} zoom={12} maxZoom={8} minZoom={6}>
          <LayersControl position="topright">
            <BaseLayer checked name="Map">
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
          </LayersControl>
          <ZoomControl position="topright" />
          <ScaleControl position="bottomright" />
          <FeatureGroup>
            <EditControl position="topright" onCreated={setAllInside} />
            <Circle center={[51.51, -0.06]} radius={200} />
          </FeatureGroup>
          {circles.map(({ _id, name, location, radius, status }: any) => (
            <Circle
              color={status === 'OK' ? '#1B915C' : '#EF2D56'}
              fillColor={status === 'OK' ? '#1B915C' : '#EF2D56'}
              key={name}
              center={[location.coordinates[1], location.coordinates[0]]}
              radius={radius}
              onClick={onClick({ _id, status })}
            >
              <Popup>
                <div>{name}</div>
                {/*
                <Button size="small" primary color="positive" onClick={setAll('OK')}>
                  Set all to OK
                </Button>
                <Button size="small" primary color="negative" onClick={setAll('EMPTY')}>
                  Set all to EMPTY
                </Button>
                */}
              </Popup>
            </Circle>
          ))}
          }
        </Map>
      </TwoColumnCollapsible.Main>
    </TwoColumnCollapsible>
  );
};
