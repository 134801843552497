import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import SpotLayout from './app-containers/SpotLayout';
import useAppContext from './app-hooks/useAppContext';

import Spot from './pages/spot';
import Admin from './modules/admin';
import Auth from './modules/auth';

const AllRoutes = [...Auth.Routes, ...Spot.Routes];
const AdminRoutes = [...Admin.Routes];

const page404 = () => <div>404</div>;

export default withRouter(({ children, location }: { children?: any; location: any }) => {
  const { isVisitor } = useAppContext();
  let Routes: any = AllRoutes;
  let Layout: any = SpotLayout;

  if (location.pathname.startsWith('/admin') && !isVisitor) {
    Routes = AdminRoutes;
    Layout = Admin.Container;
  }

  return (
    <Layout>
      <Switch>
        <Redirect exact key="home_redirect" from="/" to="/spot" />
        <Redirect exact key="home_redirect_fr" from="/fr" to="/spot?hl=fr" />
        <Redirect exact push key="home_redirect_en" from="/en" to="/spot?hl=en" />
        <Redirect exact push key="home_redirect_es" from="/es" to="/spot?hl=es" />
        {Routes}
        <Route key="page404" component={page404} />
      </Switch>
    </Layout>
  );
});
