import styled, { css } from 'styled-components';
import { media } from '../../theme/mixins';

const Container = styled.div`
  margin: 0 auto;
  position: relative;

  ${({ fluid }) =>
    fluid &&
    css`
      max-width: none;
      width: auto;

      ${media.tablet`
      margin-left: ${({ theme }) => theme.dimensions.gap}px;
      margin-right: ${({ theme }) => theme.dimensions.gap}px;
    `}
    `}

  ${({ theme, fluid }) =>
    !fluid &&
    css`
      width: 100%;

      @media screen and (min-width: ${theme.dimensions.desktop + 2 * theme.dimensions.gap}px) {
        max-width: ${theme.dimensions.desktop}px;
      }

      @media screen and (min-width: ${theme.dimensions.widescreen + 2 * theme.dimensions.gap}px) {
        max-width: ${theme.dimensions.widescreen}px;
      }

      @media screen and (min-width: ${theme.dimensions.fullhd + 2 * theme.dimensions.gap}px) {
        max-width: ${theme.dimensions.fullhd}px;
      }
    `};
`;

export default Container;
