import { css } from 'styled-components';

import { shevy } from '../../theme/style-utils';

const { content: { fontSize } } = shevy;

const controlRadius = 2;

const controlPaddingVertical = 'calc(0.375em - 1px)';
const controlPaddingHorizontal = 'calc(0.625em - 1px)';

/* This mixins is used as a common base between interaction elements like button,
   form inputs and so on, in order to have same size and behavior. */
const control = css`
  -moz-appearance: none;
  -webkit-appearance: none;
  align-items: center;
  border: 1px solid transparent;
  border-radius: ${controlRadius}px;
  box-shadow: none;
  display: inline-flex;
  font-size: ${fontSize};
  height: 2.35em;
  justify-content: flex-start;
  line-height: 1.5;
  padding-bottom: ${controlPaddingVertical};
  padding-left: ${controlPaddingHorizontal};
  padding-right: ${controlPaddingHorizontal};
  padding-top: ${controlPaddingVertical};
  position: relative;
  vertical-align: top;

  &:focus,
  &.is-focused,
  &:active,
  &.is-active {
    outline: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
`;

export default control;
