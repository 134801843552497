import React from 'react';
import styled from 'styled-components';

import { Translate } from '../react-i18n';
import { Flex, Icon, Box } from '../ui';
import Form from '../components/Form';
import Category from '../app-components/Category';

const CategoriesCheckboxes = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  marginHorizontal: 'xsmall',
  marginVertical: 'xsmall',
  wrap: 'wrap',
})`
  .field {
    margin-top: -5px;
    margin-right: 5px;
  }
`;

interface IProps {
  categories: any[];
  isChecked: (id: string) => boolean;
  onChange: (e: any, { name, value }: any) => any;
  onClick: (id: string) => any;
  onNeedMore: () => any;
  loading: boolean;
  type?: 'passion' | 'need';
}

export default ({ categories, isChecked, onClick, onChange, onNeedMore, loading, type, ...rest }: IProps) => {
  return (
    <CategoriesCheckboxes>
      {categories.map((category: any) => (
        <Category
          key={category._id}
          checked={isChecked(category._id)}
          color={category.color}
          inverseColor={category.inverseColor}
          backgroundUrl={category.backgroundUrl}
          onClick={onClick(category._id)}
        >
          <div>
            <Flex alignItems="flex-start">
              <Form.Checkbox name={category._id} onChange={onChange} value={isChecked(category._id)} />
              <h3>{category.name}</h3>
            </Flex>
            <p>{category.description}</p>
          </div>
          <img src={`/assets/categories/${category.slug}-remote-family.jpg`} alt={category.name} width="100" height="100" />
        </Category>
      ))}
      {!loading && (
        <Category onClick={onNeedMore}>
          <Box margin="medium" textAlign="center">
            <Icon name="add" />
            <br />
            {type === 'passion' && (
              <Translate id="categories:notmine:passion" defaultMessage="One of my passion is not listed here, please, help me!" />
            )}
            {type === 'need' && <Translate id="categories.notmine:need" defaultMessage="One of my need is not listed here, please, help me!" />}
          </Box>
        </Category>
      )}
      <Category empty />
      <Category empty />
      <Category empty />
      <Category empty />
    </CategoriesCheckboxes>
  );
};
