import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useI18nContext, __ } from '../react-i18n';
import { Badge } from '../ui';
import Form from '../components/Form';
import useArray from '../hooks/useArray';

const StyledSelect = styled(Form.Select)`
  min-width: 150px;
  text-align: left;
`;

const NoBadge = styled(Badge)`
  text-decoration: line-through;
`;

interface IProps {
  defaultDiseases?: string;
  onChange: (values: any[]) => any;
  onLoad: (values: any[]) => any;
  badges?: boolean;
  placeholder?: string;
}

export default ({ defaultDiseases, onChange, onLoad, badges, ...rest }: IProps) => {
  const i18nContext = useI18nContext();
  const diseases = [
    {
      _id: 'rage',
      name: __({ id: 'disease:rage', defaultMessage: 'Rage' }, i18nContext),
    },
    {
      _id: 'ebola',
      name: __({ id: 'disease:ebola', defaultMessage: 'Ebola' }, i18nContext),
    },
    {
      _id: 'dengue-fever',
      name: __({ id: 'disease:dengue-fever', defaultMessage: 'Dengue' }, i18nContext),
    },
    {
      _id: 'chikungunya',
      name: __({ id: 'disease:chikungunya', defaultMessage: 'Chikungunya' }, i18nContext),
    },
    {
      _id: 'yellow-fever',
      name: __({ id: 'disease:yellow-fever', defaultMessage: 'Yellow Fever' }, i18nContext),
    },
    {
      _id: 'lassa-fever',
      name: __({ id: 'disease:lassa-fever', defaultMessage: 'Lassa Fever' }, i18nContext),
    },
    {
      _id: 'malaria',
      name: __({ id: 'disease:malaria', defaultMessage: 'Malaria' }, i18nContext),
    },
    {
      _id: 'zika',
      name: __({ id: 'disease:zika', defaultMessage: 'Zika' }, i18nContext),
    },
    {
      _id: 'japanese-encephalitis',
      name: __({ id: 'disease:japanese-encephalitis', defaultMessage: 'Japanese Encephalitis' }, i18nContext),
    },
    {
      _id: 'typhoid',
      name: __({ id: 'disease:typhoid', defaultMessage: 'Typhoid' }, i18nContext),
    },
  ];
  const [loaded, setLoaded] = useState(false);
  const [diseaseChanged, setDiseaseChanged] = useState(0); // this is used to detect the change ONLY on checkbox update and not on initial load
  const chosenDiseases = useArray([]);

  const updateDiseases = (values: any) => {
    chosenDiseases.setValue(values || []);
    setDiseaseChanged(diseaseChanged + 1);
  };

  const options = diseases.map((l: any) => ({ value: l._id, label: l.name })).sort((a: any, b: any) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!defaultDiseases) {
      setLoaded(true);
      return;
    }
    let chosenOptions: any[] = [];
    if (diseases.length > 0 && defaultDiseases) {
      chosenOptions = defaultDiseases.split(',').map((diseaseId: any) => options.find((option: any) => option.value === diseaseId));
      chosenDiseases.setValue(chosenOptions);
    }
    const newDiseases = chosenOptions.map((option: any) => diseases.find((disease: any) => disease._id === option.value));

    onLoad(newDiseases);
    setLoaded(true);
  }, [diseases.length]);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    const newDiseases = chosenDiseases.value.map((option: any) => diseases.find((disease: any) => disease._id === option.value));
    onChange(newDiseases);
  }, [diseaseChanged]);

  if (badges) {
    return (
      <>
        {chosenDiseases.value.map((chosenDisease: any) => (
          <NoBadge color="warning" key={chosenDisease.value}>
            {chosenDisease.label}
          </NoBadge>
        ))}
      </>
    );
  }

  return (
    <StyledSelect
      key={
        `disease_picker_${chosenDiseases.value.reduce(
          (acc: string, l: any) => (acc = `${acc}_${l.value}`),
          ''
        )}` /* Modify the key because defaultValue, once set will not trigger re-render */
      }
      options={options}
      isMulti
      name="diseases"
      onChange={updateDiseases}
      defaultValue={chosenDiseases.value}
      {...rest}
    />
  );
};
