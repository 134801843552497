import { useState, useCallback } from 'react';

// https://github.com/zakariaharti/react-hookedup/blob/master/src/hooks/useArray.ts
const useArray = (initial: any) => {
  const [value, setValue] = useState(initial);
  return {
    value,
    setValue,
    has: (id: string) => !!value.find(({ _id }: any) => _id === id),
    add: useCallback((a: any) => setValue((v: any) => [...v, a]), []),
    clear: useCallback(() => setValue((): any => []), []),
    removeById: useCallback((id: string) => setValue((arr: any) => arr.filter((v: any) => v && v._id !== id)), []),
    removeIndex: useCallback(
      (index: number) =>
        setValue((v: any) => {
          v.splice(index, 1);
          return v;
        }),
      []
    ),
  };
};

export default useArray;
