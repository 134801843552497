import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, FormikActions } from 'formik';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Translate } from '../../react-i18n';
import { Button, Box, Form } from '../../ui';

interface MyFormValues {
  email: string;
  passion: string;
  message: string;
}

const ADD_CATEGORY_REQUEST = gql`
  mutation createCategoryRequest($name: String!, $email: String, $message: String) {
    createCategoryRequest(name: $name, email: $email, message: $message) {
      message {
        code
        text
      }
      result {
        _id
        name
        nbRequests
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required')
    .trim()
    .lowercase(),
});

export default ({ category, onSuccess }: { category: string; onSuccess?: () => any }) => {
  const [createCategoryRequestMutation] = useMutation(ADD_CATEGORY_REQUEST);
  const initialValues: MyFormValues = { email: '', passion: category, message: '' };

  const [congratsMessage, showCongratsMessage] = useState(false);

  const onSubmit = ({ email, passion: name, message }: MyFormValues, { setSubmitting }: FormikActions<MyFormValues>) => {
    createCategoryRequestMutation({ variables: { email, name, message } });
    setSubmitting(false);
    showCongratsMessage(true);
    setTimeout(() => {
      showCongratsMessage(false);
      if (onSuccess) {
        onSuccess();
      }
    }, 5000);
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <form onSubmit={handleSubmit}>
          <label>
            <Translate id="form.email" defaultMessage="Email" />{' '}
            <Form.Help>
              <Translate id="form.categoryrequest.email.help" defaultMessage="So that we can contact you when it's ready" />
            </Form.Help>
            <input type="email" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
            <Form.Error name="email" errors={errors} touched={touched} />
          </label>
          <label>
            <Translate id="form.passion" defaultMessage="What passion of yours do you want to add on the map?" />
            <input type="text" name="passion" onChange={handleChange} onBlur={handleBlur} value={values.passion} />
            <Form.Error name="passion" errors={errors} touched={touched} />
          </label>
          <label>
            <Translate id="form.passion.message" defaultMessage="And you can add a message to us if you need" />
            <Form.TextArea name="message" onChange={handleChange} onBlur={handleBlur} value={values.message} />
            <Form.Error name="message" errors={errors} touched={touched} />
          </label>
          {congratsMessage && (
            <Box color="positive" padding="medium" margin="medium">
              <Translate
                id="form.categoryrequest.success"
                defaultMessage="Thanks for your help, we'll let you know when we will add your passion to our list"
              />
            </Box>
          )}
          <Button color="main" primary type="submit" disabled={isSubmitting}>
            <Translate id="form.categoryrequest.submit" defaultMessage="Please, add this, I can't wait!" />
          </Button>
        </form>
      )}
    </Formik>
  );
};
