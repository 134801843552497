import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled, { css } from 'styled-components';
import gql from 'graphql-tag';
import { uniq } from 'lodash/fp';

import { Flex, Box, Loader, Boxed } from '../ui';
import { Translate } from '../react-i18n';
import Ad from '../modules/advertisement/components/Ad';

const GET_SPOT_AREAS = gql`
  query listPoisByCategories(
    $categories: [ID]
    $languages: [String]
    $diseases: [String]
    $temperature: [Float]
    $costOfLife: [Float]
    $timezone: [Float]
  ) {
    areas: listPoisByCategories(
      categories: $categories
      languages: $languages
      diseases: $diseases
      temperature: $temperature
      costOfLife: $costOfLife
      timezone: $timezone
    ) {
      nodes {
        _id
        count
        countries
        continents
        languages
        bounds
        costOfLife
        averageTemperature
        addresses
      }
    }
  }
`;

interface IProps {
  categories?: string[];
  languages?: string[];
  diseases?: string[];
  temperature?: number[];
  costOfLife?: number[];
  timezone?: number[];
  geohashCode?: string;
  showSpotList?: boolean;
  onClick: (code: string, hash: string) => void;
  onLoad: (areas: any[]) => void;
}

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

const cssDisabled = css`
  &,
  &:active,
  &:hover,
  &:visited {
    color: #ccc !important;
    cursor: not-allowed !important;
  }
`;

const Link = styled.a<{ disabled: number }>`
  ${({ disabled, theme }) => css`
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    color: ${theme.colors.mainDark};
    &:hover {
      color: ${theme.colors.main};
    }
    ${disabled && cssDisabled}
  `}
`;

const LinkSimple = styled.a<{ disabled: number }>`
  ${({ disabled }) => css`
    cursor: pointer;
    ${disabled && cssDisabled}
  `}
`;

const CountryName = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  color: #666;
`;

const ContinentTitle = styled.h2<any>`
  ${({ textAlign, theme }) => css`
    padding: ${theme.paddings.medium};
    text-align: ${textAlign};
    margin: 0;
    font-size: 35px;
    font-family: Pacifico !important;
  `}
`;

const CountriesWrapper = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  marginHorizontal: 'small',
  marginVertical: 'small',
  wrap: 'wrap',
})``;

const Item = styled('div')<{ showSpotList?: boolean; empty?: boolean; background?: string }>`
  ${({ theme, showSpotList, empty, background }) => css`
    align-items: center;
    background: ${background || 'white'};
    border-bottom: 1px solid #f5f5f5;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px 0px;
    display: flex;
    flex-direction: column;
    flex: 1 0 calc(100% - 40px);
    font-size: 16px;
    font-weight: bold;
    min-height: 50px;
    justify-content: center;
    margin: 10px 20px;
    overflow: hidden;
    padding: 5px;
    position: relative;
    text-align: center;
    transition: all 1.1s;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 4px 0px;
      background: #fafafa;
    }

    ${!showSpotList &&
      css`
      min-height: 60px;
      ${theme.breakpoint('tablet')`
      min-height: 120px;
      flex: 1 0 20%;
      `}
      ${theme.breakpoint('widescreen')`
      flex: 1 0 15%;
      `}
      ${theme.breakpoint('fullhd')`
      flex: 1 0 13%;
      `}
    `}

    ${empty &&
      css`
        min-height: 0 !important;
        height: 0 !important;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        box-shadow: none;
        border: none;
      `}
  `}
`;

const ItemTopLeft = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.7em;
  padding: 3px;
  color: #a7a7a7;
`;

const ItemTopRight = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.7em;
  padding: 3px;
  color: #a7a7a7;
`;

const LoaderWrapper = styled(Box).attrs({ padding: 'large', textAlign: 'center' })`
  font-size: 1.3em;
`;

export default React.memo(
  ({ categories, languages, diseases, temperature, costOfLife, timezone, onClick, geohashCode, showSpotList, onLoad }: IProps) => {
    const { data, loading, error } = useQuery(GET_SPOT_AREAS, { variables: { categories, languages, diseases, temperature, costOfLife, timezone } });
    const areas = (!error && data.areas && data.areas.nodes) || [];

    const onAreaCodeClick = (code: any, hash: string) => (e: any) => onClick(code, hash);

    const continents: string[] = uniq(
      areas
        .reduce((acc: any, area: any) => {
          acc.push(area.continents);
          return acc;
        }, [])
        .sort()
    );

    useEffect(() => {
      onLoad(areas);
    }, [areas.length]);

    const countries = areas.reduce((acc: any, area: any) => {
      const country = area.countries;
      const continent = area.continents;
      if (!acc[continent]) {
        acc[continent] = {};
      }

      if (!acc[continent][country]) {
        acc[continent][country] = 1;
      } else {
        acc[continent][country] = acc[continent][country] + 1;
      }
      return acc;
    }, {});

    const sortedCountries = (continent: string): string[] => Object.keys(countries[continent] || []).sort();

    const getAreaByName = (countryName: string) => areas.find((area: any) => countryName === area.countries);
    const getAreasByName = (countryName: string) => areas.filter((area: any) => countryName === area.countries);

    let j = 1;

    return (
      <Wrapper>
        {loading && (
          <LoaderWrapper>
            <small>
              <Translate id="spots:loading" defaultMessage="We're scanning all the best places in the world to find your sweet spot" />
            </small>
            <br />
            <br />
            <Loader />
          </LoaderWrapper>
        )}
        {error && (
          <Box color="negative" padding="medium" margin="medium">
            Sorry, an error occured
          </Box>
        )}
        {(!continents || continents.length === 0) && !loading && !error && (
          <Box padding="medium" textAlign="center">
            <Translate
              id="spots:no-data"
              defaultMessage="Sorry but we did not find any place in the world that suits your needs. Try being less picky :-)"
            />
          </Box>
        )}
        {continents.map((continent: string) => (
          <div key={`continent_${continent}`}>
            <ContinentTitle textAlign="center">{continent}</ContinentTitle>
            <Boxed>
              <CountriesWrapper>
                {sortedCountries(continent).map((country: string) => (
                  <React.Fragment key={country}>
                    {(j++ % 10 === 0 || j === 4) && (
                      <Item background="#EFEFEF">
                        <Ad random />
                      </Item>
                    )}
                    <Item id={country} showSpotList={showSpotList}>
                      <ItemTopLeft>🌡️ {getAreaByName(country).averageTemperature}°C</ItemTopLeft>
                      <ItemTopRight>{getAreaByName(country).costOfLife} 💰</ItemTopRight>
                      {(countries[continent][country] === 1 || !showSpotList) && (
                        <Link
                          disabled={getAreaByName(country)._id === geohashCode ? 1 : 0}
                          onClick={onAreaCodeClick(getAreaByName(country), country)}
                          id={getAreaByName(country)._id}
                        >
                          {country}
                        </Link>
                      )}
                      {countries[continent][country] > 1 && showSpotList && (
                        <>
                          <CountryName>{country}</CountryName>
                          <Flex marginHorizontal="small" wrap="wrap" alignItems="center" justifyContent="center">
                            {getAreasByName(country).map((area: any, i: number) => (
                              <LinkSimple
                                disabled={area._id === geohashCode ? 1 : 0}
                                key={area._id}
                                onClick={onAreaCodeClick(area, country)}
                                id={area._id}
                              >
                                <Translate id="spots:zone" defaultMessage="Zone" /> {i + 1}
                              </LinkSimple>
                            ))}
                          </Flex>
                        </>
                      )}
                    </Item>
                  </React.Fragment>
                ))}

                <Item empty />
                <Item empty />
                <Item empty />
                <Item empty />
                <Item empty />
              </CountriesWrapper>
            </Boxed>
          </div>
        ))}
      </Wrapper>
    );
  },
  (prevProps: IProps, nextProps: IProps) => {
    return (
      JSON.stringify(prevProps.categories) === JSON.stringify(nextProps.categories) &&
      JSON.stringify(prevProps.languages) === JSON.stringify(nextProps.languages) &&
      JSON.stringify(prevProps.diseases) === JSON.stringify(nextProps.diseases) &&
      JSON.stringify(prevProps.temperature) === JSON.stringify(nextProps.temperature) &&
      JSON.stringify(prevProps.timezone) === JSON.stringify(nextProps.timezone) &&
      prevProps.geohashCode === nextProps.geohashCode &&
      prevProps.showSpotList === nextProps.showSpotList
    );
  }
);
