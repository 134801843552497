import React, { useState } from 'react';
import { Slider } from 'react-semantic-ui-range';
import styled, { css } from 'styled-components';

import { Flex, Badge, Button, Icon } from '../ui';

const Wrapper = styled('div')<{ inverted: boolean }>`
  width: 250px;
  ${({ inverted }) => css`
    ${inverted &&
      css`
        .semantic_ui_range_inner > div:nth-child(1) {
          background-color: #2185d0 !important;
        }
        .semantic_ui_range_inner > div:nth-child(2) {
          background-color: #f8f8f8 !important;
        }
      `}
  `}
`;

interface IProps {
  defaultTimezone: number[];
  onChange: (values: any[]) => any;
  onLoad: (values: any[]) => any;
  badges?: boolean;
}

const formatUTC = (hour: number) => {
  if (hour > 0) {
    return `UTC+${hour}`;
  } else if (hour === 0) {
    return `UTC±${hour}`;
  }
  return `UTC${hour}`;
};

const MIN_TIMEZONE = -12;
const MAX_TIMEZONE = 14;

export default ({ defaultTimezone, onChange, onLoad, badges, ...rest }: IProps) => {
  // const { data: { countries: nodes = {} } = {} } = useQuery(GET_COUNTRIES);
  const [min, setMin] = useState(defaultTimezone[0] || MIN_TIMEZONE);
  const [max, setMax] = useState(defaultTimezone[1] || MAX_TIMEZONE);
  const [inverted, invert] = useState(min > max);
  const toggleInvert = () => {
    invert(!inverted);
    onTimezoneChange([min, max]);
  };

  if (badges) {
    if (min === MIN_TIMEZONE && max === MAX_TIMEZONE) {
      return null;
    }

    return (
      <>
        <Badge secondary color="dark" key="timezone_min">
          {formatUTC(min)} | {formatUTC(max)}
        </Badge>
      </>
    );
  }

  const onTimezoneChange = ([newMin, newMax]: any) => {
    const computedMin = inverted ? newMax : newMin;
    const computedMax = inverted ? newMin : newMax;

    setMin(computedMin);
    setMax(computedMax);
    onChange([computedMin, computedMax]);
  };

  const settings = {
    start: inverted ? [max, min] : [min, max],
    min: MIN_TIMEZONE,
    max: MAX_TIMEZONE,
    step: 1,
    onChange: onTimezoneChange,
  };

  return (
    <Wrapper inverted={inverted}>
      <Slider multiple color="blue" settings={settings} />
      <Flex alignItems="center">
        <Flex.Item flex="1" style={{ textAlign: 'left' }}>
          {formatUTC(min)}
        </Flex.Item>
        <Button onClick={toggleInvert} secondary customColor="#2185d0" size="medium">
          <Icon name="switchTimezones" />
        </Button>
        <Flex.Item flex="1" style={{ textAlign: 'right' }}>
          {formatUTC(max)}
        </Flex.Item>
      </Flex>
    </Wrapper>
  );
};
