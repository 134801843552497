import React from 'react';
import styled from 'styled-components';

import { Polygon, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { formatBounds } from '../utils/geo';
import './bootstrap-react-leaflet-markercluster';

const StyledTooltip = styled(Tooltip)`
  background: #333;
  color: white;
  border-color: #333;
  box-shadow: 0 0px 4px 1px rgb(255, 255, 255);
  &::before {
    border-right-color: #333;
  }
`;

export default React.memo(
  ({ areas, onAreaCodeClick }: any) => {
    return (
      <MarkerClusterGroup>
        {areas &&
          areas.map((area: any) => (
            <Polygon
              key={`polygon_${area._id}`}
              positions={formatBounds(area.bounds)}
              color="#1abc9c"
              fillColor="#1abc9c"
              onClick={onAreaCodeClick(area, area.countries)}
            >
              <StyledTooltip direction="right" opacity={1}>
                <span>{area.countries}</span>
              </StyledTooltip>
            </Polygon>
          ))}
      </MarkerClusterGroup>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.areas.length === nextProps.areas.length;
  }
);
