import * as React from 'react';
import { Mention } from 'react-twitter-widgets';

import { Translate, useI18nContext, __ } from '../react-i18n';
import { Button, Flex, Modal, Box } from '../ui';
import useModal from '../hooks/useModal';

export default () => {
  const i18nContext = useI18nContext();
  const { modalOpened, showModal, hideModal } = useModal();

  const emailSubject: string = __(
    { id: 'poi:become-member-modal:mail-subject', defaultMessage: "I'm interested in getting membership" },
    i18nContext
  );
  const emailBody: string = __(
    {
      id: 'poi:become-member-modal:mail-body',
      defaultMessage: `
...
What feature(s) do you need most?
--------------------------


How much you think would be a good price for this service?
--------------------------


Do you want a coupon code?
--------------------------


Anything else you want to add?
--------------------------


Thanks
`,
    },
    i18nContext
  );

  const searchParams = new URLSearchParams();
  searchParams.set('subject', emailSubject);
  searchParams.set('body', emailBody);

  return (
    <>
      <Button primary size="small" color="main" onClick={showModal}>
        <Translate id="poi:become-member-modal:button" defaultMessage="Become member" />
      </Button>
      <Modal open={modalOpened} onClose={hideModal} size="small">
        <Modal.Content>
          <h2>
            <Translate id="poi:become-member-modal:title" defaultMessage="Thank you so much for your interest" />
          </h2>
          <Box margin="small">
            <Translate
              id="poi:become-member-modal:description"
              defaultMessage="We're still a pretty young service, and we want to help digital nomads, remote families and more find their sweet spot, so thank you for your implication"
            />
          </Box>
          <Box marginHorizontal="small" marginVertical="large">
            <Translate
              id="poi:become-member-modal:help"
              defaultMessage="We are still fine tuning the service and have no price yet to give you, so please tell us what you think on {email}"
              values={{ email: <a href={`mailto:hello@remote-family.com?${searchParams}`}>hello@remote-family.com</a> }}
            />
            <br />
            <br />
            <ul>
              <li>
                <Translate id="poi:become-member-modal:li-1" defaultMessage="what feature(s) you need most" />
              </li>
              <li>
                <Translate id="poi:become-member-modal:li-2" defaultMessage="how much you think would be a good price for this service" />
              </li>
              <li>
                <Translate id="poi:become-member-modal:li-3" defaultMessage="if you would like to get a coupon code! (reserved to 100 first users)" />
              </li>
            </ul>
          </Box>
          <Box margin="small">
            <Flex marginHorizontal="xsmall">
              <Translate id="poi:add-twitter:description" defaultMessage="Or alternatively you can" />
              <Mention
                username="remote_family"
                options={{
                  text: __(
                    {
                      id: 'poi:become-member-modal:feedback:twitter',
                      defaultMessage: 'Hey, I want a membership to find my sweet remote spot',
                    },
                    i18nContext
                  ),
                }}
              />
            </Flex>
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Button tertiary color="faded" size="small" onClick={hideModal}>
            <Translate id="poi:become-member-modal:cancel" defaultMessage="In fact, no thanks" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
