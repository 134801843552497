import * as React from 'react';
import styled, { css } from 'styled-components';
import { sample } from 'lodash/fp';

import { Translate } from '../../../react-i18n';
import { Flex, Button } from '../../../ui';

interface IProps {
  random?: boolean;
  withPromotion?: boolean;
  withImage?: boolean;
  withText?: boolean;
  brand?: 'transferwise' | 'safetywing' | 'remotefamily';
}

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  { [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>> }[Keys];

interface IAdvertisers {
  [key: string]: {
    icon: string;
    text: string | React.ReactNode;
    buttonText: string | React.ReactNode;
    customColor?: string;
    color?: string;
    url: string;
    target: '_top' | '_blank';
  };
}

const advertisers: IAdvertisers = {
  safetywing: {
    icon: 'https://www.google.com/s2/favicons?domain=www.safetywing.com',
    text: <Translate id="advertisement:safetywing:text" defaultMessage="Get your travel medical insurance" />,
    buttonText: <Translate id="advertisement:safetywing:button" defaultMessage="Get medical cover" />,
    customColor: '#5CC6C7',
    url: 'https://www.safetywing.com/a/24744971',
    target: '_blank',
  },
  transferwise: {
    icon: 'https://www.google.com/s2/favicons?domain=www.transferwise.com',
    text: <Translate id="advertisement:transferwise:text" defaultMessage="Get the real exchange rate and fair fees" />,
    buttonText: <Translate id="advertisement:transferwise:button" defaultMessage="Send money" />,
    customColor: '#00B9FF',
    url: 'https://prf.hn/l/7BOJNGr',
    target: '_blank',
  },
  remotefamily: {
    icon: 'https://www.google.com/s2/favicons?domain=www.remote-family.com',
    text: <Translate id="advertisement:remotefamily:text" defaultMessage="Promote your digital product here, starting at 10$" />,
    buttonText: <Translate id="advertisement:remotefamily:button" defaultMessage="Promote" />,
    color: 'accent',
    target: '_top',
    url: 'mailto:promote@remote-family.com?subject=I want to promote my product on remote-family.com',
  },
};

const Ad = styled(Flex)`
  position: relative;
`;

const Promotion = styled('div')`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 7px;
    color: ${theme.colors.faded};
  `}
`;

export default ({ brand, random, withPromotion = true, withImage = true, withText = true }: RequireAtLeastOne<IProps, 'random' | 'brand'>) => {
  const advertiserKeys: string[] = Object.keys(advertisers);

  // @ts-ignore
  const key: string = random ? sample(advertiserKeys) : brand;

  const advertiser = advertisers[key];

  return (
    <Ad direction="column" marginVertical="2px" alignItems="center">
      {withPromotion && (
        <Promotion>
          <Translate id="advertismeent:promotion" defaultMessage="Promotion" textTransform="uppercase" />
        </Promotion>
      )}
      {withImage && <img src={advertiser.icon} alt={key} />}
      {withText && (
        <p>
          <small>{advertiser.text}</small>
        </p>
      )}
      <Button
        as="a"
        secondary
        color={advertiser.color}
        customColor={advertiser.customColor}
        size="xsmall"
        href={advertiser.url}
        target={advertiser.target}
        rel="sponsored"
      >
        {advertiser.buttonText}
      </Button>
    </Ad>
  );
};
