import React from 'react';
// import React, { useState } from 'react';
// import { orderBy } from 'lodash/fp';

const getEmoticon = (field: string) => {
  switch (field) {
    case 'averageTemperature':
      return '🌡️';
    case 'costOfLife':
      return '💰';
    case 'name':
      return '';
    default:
      return field;
  }
};

const format = (field: string, country: any) => {
  switch (field) {
    case 'averageTemperature':
      return `${country[field]}°C`;
    case 'costOfLife':
      return country[field] && country[field].toFixed(2);
    default:
      return country[field];
  }
};

export default ({ countries, fields }: { countries: any; fields: string[] }) => {
  // const [sortField, setSortField] = useState<null | string>(fields[0]);
  // const [order, setOrder] = useState(1);
  // const sort = (type: string) => setSortField(type);
  // const toggleOrder = () => !!order;
  //
  // const orderedCountries = orderBy([sortField], [order === 1 ? 'asc' : 'desc'])(countries);

  return (
    <table>
      <thead>
        <tr>
          {fields.map((field: string) => (
            <th key={`th_${field}`}>{getEmoticon(field)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {countries.map((country: any) => (
          <tr key={country._id}>
            {fields.map((field: string) => (
              <td key={`${country._id}_${field}`}>{format(field, country)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
