import * as React from 'react';
import styled from 'styled-components';
import { Mention } from 'react-twitter-widgets';

import { isMobileDevice } from '../utils/detection';
import { useI18nContext, __, Translate } from '../react-i18n';
import ShareBar from '../modules/share/components/ShareBar';
import { Flex } from '../ui';

const SLACK_JOIN_URL = 'https://join.slack.com/t/remotefamily/shared_invite/zt-czzxzdj9-dOws4SCCw9ZgSfSeRjvu4w';

const MentionWrapper = styled('div')`
  position: relative;
  flex: 1;

  iframe {
    position: absolute !important;
    top: calc(50% - 10px);
    right: 0;
  }
`;

const FullFlex = styled(Flex)`
  width: 100%;
  > *:nth-child(2) {
    text-align: center;
  }
  > *:nth-child(3) {
    min-width: 160px;
  }
`;

const ImageTextLink = styled('a')`
  color: white;
  margin: 0 auto;
  display: inline-block;

  > span {
    display: flex;
    align-items: center;
    > img {
      margin: 0;
    }
  }

  &:hover {
    color: #aaa;
    img {
      opacity: 0.6;
    }
  }
`;

const SlackImage = () => <img src="/assets/social/join-slack-channel-white-remote-family.png" height="auto" width="70" alt="join slack" />;

export default () => {
  const i18nContext = useI18nContext();

  return (
    <FullFlex marginHorizontal="small" justifyContent="space-between" alignItems="center">
      <Flex.Item flex="1">
        <ShareBar
          url="https://www.remote-family.com"
          title={__({ id: 'share:title', defaultMessage: 'Awesome tool for #DigitalNomad' }, i18nContext)}
        />
      </Flex.Item>
      <Flex.Item flex="1" textAlign="center">
        <ImageTextLink target="_blank" rel="noopener" href={SLACK_JOIN_URL}>
          {isMobileDevice() ? (
            <Translate
              id="share:slack-small"
              defaultMessage="Join {image}"
              values={{
                image: <SlackImage />,
              }}
            />
          ) : (
            <Translate
              id="share:slack"
              defaultMessage="Join the {image} channel here"
              values={{
                image: <SlackImage />,
              }}
            />
          )}
        </ImageTextLink>
      </Flex.Item>
      <Flex.Item flex="1">
        <MentionWrapper>
          <Mention
            username="remote_family"
            options={{
              text: __({ id: 'feedback:global:twitter', defaultMessage: 'Hey, awesome tool for #DigitalNomad, can you please add ...' }, i18nContext),
            }}
          />
        </MentionWrapper>
      </Flex.Item>
    </FullFlex>
  );
};
