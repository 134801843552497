import * as React from 'react';
import { Mention } from 'react-twitter-widgets';

import { Translate, useI18nContext, __ } from '../react-i18n';
import { Button, Flex, Modal, Box } from '../ui';
import useModal from '../hooks/useModal';

export default () => {
  const i18nContext = useI18nContext();
  const { modalOpened, showModal, hideModal } = useModal();

  const emailSubject: string = __({ id: 'poi:add-modal:mail-subject', defaultMessage: 'Can you please add this missing spot?' }, i18nContext);
  const emailBody: string = __(
    {
      id: 'poi:add-modal:mail-body',
      defaultMessage: `
...

Please, add this spot that is missing:
- name:
- address:
- latitude:
- longitude:
- category:

or alternatively, here is where you can find the data:
- google maps link or url:
- category:

Thanks
`,
    },
    i18nContext
  );

  const searchParams = new URLSearchParams();
  searchParams.set('subject', emailSubject);
  searchParams.set('body', emailBody);

  return (
    <>
      <Button primary size="xxsmall" color="accent" onClick={showModal}>
        <Translate id="poi:add-modal:button" defaultMessage="One spot is missing!" />
      </Button>
      <Modal open={modalOpened} onClose={hideModal} size="small">
        <Modal.Content>
          <h2>
            <Translate id="poi:add-modal:title" defaultMessage="Thank you so much for your interest" />
          </h2>
          <Box margin="small">
            <Translate
              id="poi:add-modal:description"
              defaultMessage="We're still a pretty young service, and we want to help digital nomads, remote families and more find their sweet spot, so thank you for your implication"
            />
          </Box>
          <Box marginHorizontal="small" marginVertical="large">
            <Translate
              id="poi:add-modal:help"
              defaultMessage="If you can give us the latitude, longitude, address and name or ideally a google maps link, that would be awesome"
            />
          </Box>
          <Box margin="small">
            <Translate id="poi:add-modal:mail-us" defaultMessage="Until you can enter all the data directly, please send us an email" />{' '}
            <a href={`mailto:hello@remote-family.com?${searchParams}`}>hello@remote-family.com</a>
          </Box>
          <Box margin="small">
            <Flex marginHorizontal="xsmall">
              <Translate id="poi:add-twitter:description" defaultMessage="Or alternatively you can" />
              <Mention
                username="remote_family"
                options={{
                  text: __(
                    {
                      id: 'poi:add-modal:feedback:twitter',
                      defaultMessage: 'Hey, here is a remote spot which should be included : ',
                    },
                    i18nContext
                  ),
                }}
              />
            </Flex>
          </Box>
        </Modal.Content>
        <Modal.Actions>
          <Button tertiary color="faded" size="small" onClick={hideModal}>
            <Translate id="poi:add-modal:cancel" defaultMessage="In fact, no thanks" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
