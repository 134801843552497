const BadgeTheme = {
  borderRadius: '4px',
  padding: '0.75rem',
  fontSize: '0.75rem',
  fontFamily: 'inherit',

  sizes: {
    xxsmall: 0.6,
    xsmall: 0.75,
    small: 0.9,
    medium: 1,
    large: 1.25,
    xlarge: 1.5,
    xxlarge: 2,
  },

  /* Modifiers */
  pill: {
    borderRadius: '10000px',
  },
};

export default BadgeTheme;
