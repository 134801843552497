import React from 'react';
import PropTypes from 'prop-types';
import round from 'lodash/round';
import styled from 'styled-components';


const MAPPING_LOCALES_TO_LOCALEID = {
  fr: 'fr-FR',
  en: 'en-US',
  'zh-Hans': 'zh-CN',
  'zh-Hant': 'zh-TW',
};

class Number extends React.Component {
  render() {
    const { value, precision, options, padded, paddedColor, percent } = this.props;
    if (value === undefined || value === null) {
      return '';
    }
    const locale = this.props.locale || this.context.locale;

    if (padded) {
      const NumberPrefix = styled.span`color: ${paddedColor};`;

      let [paddedLeft, paddedRight] = (padded.includes('.')) ? padded.split('.') : [padded, '0'];
      paddedLeft = parseInt(paddedLeft, 10);
      paddedRight = parseInt(paddedRight, 10);

      if (!value) {
        return (
          <div>
            {paddedLeft > 0 && <NumberPrefix>{''.padStart(paddedLeft, 0)}</NumberPrefix>}
            {paddedRight > 0 && '.'}
            {paddedRight > 0 && <NumberPrefix>{''.padStart(paddedRight, 0)}</NumberPrefix>}
          </div>);
      }
      const [leftValue, rightValue] = value.toString().split('.');
      const digitsLeft = leftValue.length;
      const digitsRight = rightValue ? rightValue.length : 0;
      return (
        <div>
          {(paddedLeft - digitsLeft > 0) && <NumberPrefix>{''.padStart(paddedLeft - digitsLeft, 0)}</NumberPrefix>}
          {value}
          {!rightValue && paddedRight > 0 && '.'}
          {(paddedRight - digitsRight > 0) && <NumberPrefix>{''.padStart(paddedRight - digitsRight, 0)}</NumberPrefix>}
        </div>);
    }

    if (percent) {
      const percentOptions = {
        ...options,
        style: 'percent',
        maximumFractionDigits: precision,
      };
      return Number.getFormatted(locale, parseFloat(value), percentOptions);
    }

    return Number.getFormatted(locale, round(parseFloat(value), precision), options);
  }
}

Number.getFormatted = (locale, value, options = {}) => {
  if (value === undefined || value === null) {
    return '';
  }

  return value.toLocaleString(MAPPING_LOCALES_TO_LOCALEID[locale] || locale, options);
};

Number.propTypes = {
  locale: PropTypes.string,
  precision: PropTypes.number,
  value: PropTypes.number,
  padded: PropTypes.string,
  paddedColor: PropTypes.string,
  options: PropTypes.object,
  percent: PropTypes.bool,
};

Number.defaultProps = {
  value: null,
  padded: null,
  paddedColor: 'lightgray',
  locale: null,
  precision: 2,
  options: {},
  percent: false,
};

Number.contextTypes = {
  locale: PropTypes.string,
};

export default Number;
