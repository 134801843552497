import areIntlLocalesSupported from 'intl-locales-supported';
import { addLocaleData } from 'react-intl';

const localesMyAppSupports = process.env.REACT_APP_I18N_LANGUAGES.split(',');

if (global.Intl) {
  // Determine if the built-in `Intl` has the locale data we need.
  if (!areIntlLocalesSupported(localesMyAppSupports)) {
    // `Intl` exists, but it doesn't have the data we need, so load the
    // polyfill and patch the constructors we need with the polyfill's.
    const IntlPolyfill = require('intl');
    Intl.NumberFormat = IntlPolyfill.NumberFormat;
    Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat;
    // localesMyAppSupports.forEach(locale => {
    //   require(`intl/locale-data/jsonp/${locale}`);
    // });
    require(`intl/locale-data/jsonp/en`);
    require(`intl/locale-data/jsonp/fr`);
    require(`intl/locale-data/jsonp/es`);
  }
} else {
  // No `Intl`, so use and load the polyfill.
  global.Intl = require('intl');
  // localesMyAppSupports.forEach(locale => {
  //   require(`intl/locale-data/jsonp/${locale}`);
  // });
  require(`intl/locale-data/jsonp/en`);
  require(`intl/locale-data/jsonp/fr`);
  require(`intl/locale-data/jsonp/es`);
}

// localesMyAppSupports.forEach(locale => {
//   addLocaleData(require(`react-intl/locale-data/${locale}`));
// });
addLocaleData(require(`react-intl/locale-data/en`));
addLocaleData(require(`react-intl/locale-data/fr`));
addLocaleData(require(`react-intl/locale-data/es`));
