import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import * as fp from 'lodash/fp';

import { Badge, Loader } from '../ui';
import Form from '../components/Form';
import useArray from '../hooks/useArray';

const GET_LANGUAGES = gql`
  {
    languages {
      count
      nodes {
        _id
        name
      }
    }
  }
`;

const COMMON_LANGUAGES = ['en', 'es', 'fr', 'ru', 'zh', 'de'];

const StyledSelect = styled(Form.Select)`
  min-width: 150px;
  text-align: left;
`;

interface IProps {
  defaultLanguages: string;
  onChange: (values: any[]) => any;
  onLoad: (values: any[]) => any;
  badges?: boolean;
  placeholder?: string;
}

export default ({ defaultLanguages, onChange, onLoad, badges, ...rest }: IProps) => {
  const { data, loading } = useQuery(GET_LANGUAGES);
  const languages = fp.get('languages.nodes')(data) || [];
  const [loaded, setLoaded] = useState(false);
  const [languageChanged, setLanguageChanged] = useState(0); // this is used to detect the change ONLY on checkbox update and not on initial load
  const chosenLanguages = useArray([]);

  const updateLanguages = (values: any) => {
    chosenLanguages.setValue(values || []);
    setLanguageChanged(languageChanged + 1);
  };

  const options = languages.map((l: any) => ({ value: l._id, label: l.name })).sort((a: any, b: any) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (!defaultLanguages) {
      setLoaded(true);
      return;
    }
    let chosenOptions = [];
    if (languages.length > 0 && defaultLanguages) {
      chosenOptions = defaultLanguages.split(',').map((languageId: any) => options.find((option: any) => option.value === languageId));
      chosenLanguages.setValue(chosenOptions);
    }
    const newLanguages = chosenOptions.map((option: any) => languages.find((language: any) => language._id === option.value));

    onLoad(newLanguages);
    setLoaded(true);
  }, [languages]);

  useEffect(() => {
    if (!loaded) {
      return;
    }
    const newLanguages = chosenLanguages.value.map((option: any) => languages.find((language: any) => language._id === option.value));
    onChange(newLanguages);
  }, [languageChanged]);

  if (badges) {
    return (
      <>
        {loading && <Loader width={30} height={30} />}
        {chosenLanguages.value.map((chosenLanguage: any) => (
          <Badge color="faded" key={chosenLanguage.value}>
            {chosenLanguage.label}
          </Badge>
        ))}
      </>
    );
  }

  if (!languages || !loaded) {
    return null;
  }

  return (
    <StyledSelect
      key={
        `language_picker_${chosenLanguages.value.reduce(
          (acc: string, l: any) => (acc = `${acc}_${l.value}`),
          ''
        )}` /* Modify the key because defaultValue, once set will not trigger re-render */
      }
      options={[
        {
          label: '-',
          options: options.filter((l: any) => COMMON_LANGUAGES.includes(l.value)),
        },
        {
          label: '-',
          options: options.filter((l: any) => !COMMON_LANGUAGES.includes(l.value)),
        },
      ]}
      isMulti
      name="languages"
      onChange={updateLanguages}
      defaultValue={chosenLanguages.value}
      {...rest}
    />
  );
};
