import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import FullPageLayout from '../../../containers/FullPageLayout';
import { Logo, Flex } from '../../../ui';

// @ts-ignore
const Header = styled(Flex).attrs({
  marginHorizontal: 'small',
})`
  ${({ theme }: { theme: any }) => css`
    background: ${theme.backgroundColors.dark};
    height: 60px;
  `}
`;

const FooterStyled = styled.footer`
  ${({ theme }: { theme: any }) => css`
    display: flex;
    align-items: center;
    background: ${theme.backgroundColors.dark};
    height: 60px;
  `}
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

interface IProps {
  children: any;
  active?: 'spot';
}

export default ({ children, active }: IProps) => {
  return (
    <FullPageLayout>
      <FullPageLayout.Header as={Header}>
        <Flex.Item as={Flex} alignItems="center">
          <LogoLink to={`/spot`}>
            <Logo color="white" />
          </LogoLink>
        </Flex.Item>
        <Flex.Item grow="1" as={Flex} alignItems="stretch">
          <Link to="/admin/worldmapcircles">World Map Circles</Link>
        </Flex.Item>
      </FullPageLayout.Header>
      <FullPageLayout.Main>{children}</FullPageLayout.Main>
      <FullPageLayout.Footer as={FooterStyled}>Footer</FullPageLayout.Footer>
    </FullPageLayout>
  );
};
