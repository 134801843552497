import React, { createContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

const ME_QUERY = gql`
  {
    me {
      _id
      userName
    }
  }
`;

export interface IAppContext {
  isVisitor: boolean;
}

export const AppContext = createContext({ isVisitor: true });

export default ({ children }: { children?: any }) => {
  const { data } = useQuery(ME_QUERY);

  const appContext: IAppContext = {
    isVisitor: !(data && data.me && data.me._id),
  };

  return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
};
