import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollHandler = ({ location }: any) => {
  useEffect(() => {
    let element: any;
    const hash = location.hash.replace('#', '');
    const rootElement = document.querySelector('#root > div > main > div > aside > div');
    if (!rootElement || !hash) {
      return;
    }
    const findHash = setInterval(() => {
      element = document.getElementById(hash);
      if (element) {
        clearInterval(findHash);

        setTimeout(() => {
          rootElement.scrollTo({
            behavior: element ? 'smooth' : 'auto',
            top: element ? element.offsetTop : 0,
          });
        }, 100);
      }
    }, 1000);
  }, [location]);

  return null;
};

export default withRouter(ScrollHandler);
