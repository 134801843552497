import styled, { keyframes, css } from 'styled-components';

export const translateAnimation = keyframes`
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(-100%);
  }
`;

const Marquee = styled('div')`
  ${({ theme }) => css`
    white-space: nowrap;
    animation: ${translateAnimation} 10s infinite linear;
    ${theme.breakpoint('tablet')`
      animation: none;
    `}
  `}
`;

export default Marquee;
