import React from 'react';
import styled from 'styled-components';

import Form from 'semantic-ui-react/dist/es/collections/Form';
import Checkbox from 'semantic-ui-react/dist/es/modules/Checkbox';

import Select from './Select';
import SelectEntity from './SelectEntity';
import { Color } from '../../ui';

const onCheckboxChange = (name: string, onChange: any) => (e: any, data: any) => onChange(e, { name, value: data.checked });

const FormCheckbox = ({ name, value, checked, onChange, ...props }: any) => (
  <Form.Field>
    <Checkbox name={name} onChange={onCheckboxChange(name, onChange)} checked={value !== undefined ? value : checked} {...props} />
  </Form.Field>
);

interface IFormError {
  errors: any;
  touched: any;
  name: string;
}

const ColorStyled = styled(Color)`
  font-weight: normal;
`;
const FormError = ({ errors, touched, name, ...rest }: IFormError) => {
  return (
    <ColorStyled color="negative" {...rest}>
      {errors[name] && touched[name] && errors[name]}
    </ColorStyled>
  );
};

const FormHelp = (props: any) => {
  return <ColorStyled color="faded" {...props} />;
};

export default {
  Checkbox: FormCheckbox,
  Error: FormError,
  Help: FormHelp,
  Select,
  SelectEntity,
  TextArea: Form.TextArea,
};
