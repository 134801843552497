import * as React from 'react';
import * as ReactDOMServer from 'react-dom/server';
import IntlProvider2 from './IntlProvider/IntlProvider';
import Translate2 from './Translate';

interface IIntlMessage {
  id: string;
  defaultMessage: string;
  values?: any;
}

interface IIntlContext {
  locales?: string[];
  locale?: string;
  messages?: any;
}

const UNESCAPED_CHARS: any = {
  '&#x27;': "'",
  '&lt;': '<',
  '&gt;': '>',
  '&amp;': '&',
  '&quot;': '"',
};

const unescape = (str: string) => {
  let newStr = str;
  const keys = Object.keys(UNESCAPED_CHARS);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    newStr = newStr.split(key).join(UNESCAPED_CHARS[key]);
  }
  return newStr;
};

export const __ = (props: IIntlMessage, { locales, locale, messages }: IIntlContext = {}): string => {
  if (!locales) {
    return props.defaultMessage;
  }

  const i18nNode: any = (
    <IntlProvider2 locales={locales} locale={locale} messages={messages}>
      <Translate2 asString={true} {...props} />
    </IntlProvider2>
  );

  return unescape(ReactDOMServer.renderToStaticMarkup(i18nNode));
};

export const Translate = Translate2;
export const IntlProvider = IntlProvider2;
export { default as TranslateDate } from './TranslateDate';
export { default as useI18nContext } from './hooks/useI18nContext';
