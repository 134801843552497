export const formatBounds = (bounds: any) => {
  const t: any = [];
  bounds.forEach((bound: any) => {
    t.push([bound[1], bound[0]]);
  });
  return t;
};

export const getCenterFromBounds = (bounds: any) => {
  return [(bounds[0][1] + bounds[1][1]) / 2, (bounds[1][0] + bounds[2][0]) / 2];
};

export const getCornersFromBounds = (bounds: any) => {
  return {
    swLat: bounds[0][0],
    swLng: bounds[0][1],
    neLat: bounds[2][0],
    neLng: bounds[2][1],
  };
};

export const simplifyBounds = (bounds: any) => {
  const newLatSW = Math.floor(bounds[0][1] * 1) / 1;
  const newLngSW = Math.floor(bounds[0][0] * 1) / 1;
  const newLatNE = Math.ceil(bounds[2][1] * 1) / 1;
  const newLngNE = Math.ceil(bounds[2][0] * 1) / 1;
  return [[newLngSW, newLatSW], [newLngSW, newLatNE], [newLngNE, newLatNE], [newLngNE, newLatSW], [newLngSW, newLatSW]];
};

export const formatPosition: any = (coordinates: any) => {
  return [coordinates[1], coordinates[0]];
};

export const withinBounds = (point: number[], vs: number[][]) => {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
  const [x, y] = point;

  let inside = false;
  for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    const [xi, yi] = vs[i];
    const [xj, yj] = vs[j];

    // @ts-ignore
    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
};
