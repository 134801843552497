import * as React from 'react';
import { Form } from 'semantic-ui-react';
import Async from 'react-select/async';

interface Node {
  [key: string]: any;
}

const getEntityOptionField = (field: string) => (option: Node) => option[field];
// this is to have the same signature as the other fields
const onEntitySelectChange = (
  name: string,
  onChange: (e?: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>, data?: any) => void
) => (data: any) => onChange(undefined, { name, value: data });

interface AsyncSelectExtended {
  label?: string;
  labelField: string;
  valueField: string;
  onChange: (e?: React.ChangeEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>, data?: any) => void;
  name: string;
  loadOptions: (inputValue: string, callback: (options: any) => void) => Promise<any> | void;
}

export default ({ label, labelField, valueField = '_id', onChange, name, loadOptions, ...rest }: AsyncSelectExtended) => (
  <Form.Field>
    {label && <label htmlFor={name}>{label}</label>}
    <Async
      isClearable
      onChange={onEntitySelectChange(name, onChange)}
      getOptionValue={getEntityOptionField(valueField)}
      getOptionLabel={getEntityOptionField(labelField)}
      loadOptions={loadOptions}
      {...rest}
    />
  </Form.Field>
);
