/* eslint-disable jsx-a11y/media-has-caption, jsx-a11y/click-events-have-key-events */
import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import cookie from 'react-cookies';

import { IntlProvider as ReactIntlProvider } from 'react-intl';

const LANGUAGE_NAMES = {
  fr: 'Français',
  en: 'English',
  es: 'Español',
  'zh-Hans': '中文简体',
  'zh-Hant': '中文繁體',
};

const LANGUAGE_URL_CODES = {
  fr: 'fr',
  en: 'en',
  es: 'es',
  // zh: 'zh-Hans',
  // 'zh-SG': 'en',
  // 'zh-TW': 'zh-Hant',
  // 'zh-HK': 'zh-Hant',
};

const LANGUAGE_URL_PARAMETER = 'hl';

const getLocaleFromNavigator = defaultLocale => {
  if (typeof navigator === 'undefined') {
    return defaultLocale;
  }

  let language = navigator.language || navigator.userLanguage;
  if (!language) {
    return defaultLocale;
  }
  language = language.toLowerCase();

  if (['zh-hk', 'zh-tw'].includes(language)) return 'zh-Hant';
  if (['zh-sg'].includes(language)) return 'en';
  if (language.startsWith('en')) return 'en';
  if (language.startsWith('es')) return 'es';
  if (language.startsWith('fr')) return 'fr';
  if (language.startsWith('zh')) return 'zh-Hans';

  return defaultLocale;
};

export const I18nContext = createContext({});

class IntlProvider extends React.Component {
  constructor(props) {
    super(props);
    let localeInUrl;

    if (typeof window !== 'undefined') {
      const { searchParams } = new URL(window.location.href);
      localeInUrl = searchParams && searchParams.get(LANGUAGE_URL_PARAMETER);
    }

    const defaultLocale = localeInUrl || cookie.load('locale') || getLocaleFromNavigator(props.locale);

    this.state = {
      locale: defaultLocale,
      messages: props.messages || { [defaultLocale]: {} },
    };
  }

  getChildContext() {
    return {
      locales: this.props.locales,
      messages: this.state.messages,
      locale: this.state.locale,
      defaultLocale: this.props.locale,
      localeNames: this.getLocaleNames(),
      otherLocaleNames: this.getOtherLocaleNames(),
      switchLocale: this.switchLocale,
      languageUrlCodes: LANGUAGE_URL_CODES,
      languageUrlParam: LANGUAGE_URL_PARAMETER,
    };
  }

  componentDidMount() {
    this.switchLocale(this.state.locale);
  }

  getLocaleNames() {
    return this.props.locales.map(locale => ({
      key: locale,
      value: locale,
      text: LANGUAGE_NAMES[locale] || locale,
    }));
  }

  getOtherLocaleNames() {
    return this.getLocaleNames().filter(name => name.id !== this.state.locale);
  }

  removeLangParameterFromUrl() {
    const { searchParams, pathname } = new URL(window.location.href);
    searchParams.delete(LANGUAGE_URL_PARAMETER);
    const searchParamsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
    window.history.replaceState('', document.title, `${pathname}${searchParamsString}`);
  }

  switchLocale = _locale => {
    const { messages } = this.state;
    const locale = LANGUAGE_NAMES[_locale] ? _locale : 'en';

    const cookieOptions = { path: '/', expires: 0 };

    if (process.env.REACT_APP_COOKIE_DOMAIN) {
      cookieOptions.domain = process.env.REACT_APP_COOKIE_DOMAIN;
    }

    cookie.save('locale', locale, cookieOptions);

    this.removeLangParameterFromUrl();
    if (messages[locale] && !isEmpty(messages[locale])) {
      this.setState({ locale });
      return;
    }

    const newMessages = require(`../../translations/${locale}`);

    this.setState({
      locale,
      messages: {
        ...messages,
        [locale]: newMessages,
      },
    });
  };

  render() {
    const { locale, messages } = this.state;
    return (
      <ReactIntlProvider locale={locale} messages={messages[locale]}>
        <I18nContext.Provider value={this.getChildContext()}>{this.props.children}</I18nContext.Provider>
      </ReactIntlProvider>
    );
  }
}

IntlProvider.propTypes = {
  children: PropTypes.node,
  locales: PropTypes.array,
  locale: PropTypes.string,
  messages: PropTypes.object,
};
IntlProvider.defaultProps = {
  children: null,
  locales: ['en'],
  locale: 'en',
  messages: {},
};
IntlProvider.childContextTypes = {
  locales: PropTypes.array,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
  switchLocale: PropTypes.func,
  languageUrlCodes: PropTypes.object,
  languageUrlParam: PropTypes.string,
  messages: PropTypes.object,
  localeNames: PropTypes.array,
  otherLocaleNames: PropTypes.array,
};

export default IntlProvider;
