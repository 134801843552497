import { useState } from 'react';

interface IReturn {
  modalOpened: boolean;
  setModalOpened: (modalOpened: boolean) => void;
  toggleModal: () => void;
  showModal: () => void;
  hideModal: () => void;
}

const useModal: () => IReturn = () => {
  const [modalOpened, setModalOpened] = useState(false);

  const toggleModal = () => {
    setModalOpened(!modalOpened);
  };

  const showModal = () => {
    setModalOpened(true);
  };

  const hideModal = () => {
    setModalOpened(false);
  };

  return {
    modalOpened,
    setModalOpened,
    toggleModal,
    showModal,
    hideModal,
  };
};

export default useModal;
