import React from 'react';
import { setLightness } from 'polished';
import styled, { css } from 'styled-components';

import { block, fontWeights } from '../../theme/mixins';
import { getInvertColor } from '../../theme/style-utils';

export interface IBadgeProps {
  children: any;
  as?: any;
  pill?: boolean;
  color?: string;
  customColor?: string;
  secondary?: boolean;
  uppercase?: boolean;
  size?: any;
}

const Badge = styled<any>(({ as = 'span', pill, color, customColor, secondary, uppercase, size, ...props }: IBadgeProps) =>
  React.createElement(as, props)
)`
  ${block}
  ${fontWeights}
  ${({ theme, pill, color, customColor, secondary, uppercase, size }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    border-radius: ${theme.Badge.borderRadius};
    height: 2em;
    padding-left: ${theme.Badge.padding};
    padding-right: ${theme.Badge.padding};
    line-height: 1.5;
    font-size: ${theme.Badge.fontSize};
    font-family: ${theme.Badge.fontFamily};
    white-space: nowrap;

    /* Modifiers */
    ${pill &&
      css`
        border-radius: ${theme.Badge.pill.borderRadius};
      `}

    /* Generated colors */
    ${Object.keys(theme.colors).map(
      (colorKey: string) => css`
        ${color === colorKey &&
          css`
            background-color: ${theme.colors[colorKey]};
            color: ${getInvertColor(theme.colors[colorKey])};
          `}
      `
    )};

    ${customColor &&
      css`
        background-color: ${customColor};
        color: ${getInvertColor(customColor)};
      `}

    ${secondary &&
      css`
        /* Generated colors */
        ${Object.keys(theme.colors).map(
          (colorKey: string) => css`
            ${color === colorKey &&
              css`
                color: ${theme.colors[colorKey]};
                background-color: ${setLightness(0.95, theme.colors[colorKey])};
              `}
          `
        )};

        ${customColor &&
          css`
            color: ${customColor};
            background-color: ${setLightness(0.95, customColor)};
          `}
      `}

    ${uppercase &&
      css`
        text-transform: uppercase;
      `}

    ${size &&
      css`
        font-size: ${theme.Badge.sizes[size] ? `${theme.Badge.sizes[size]}rem` : size};
      `};
  `};
`;

export default Badge;
