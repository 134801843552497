import React from 'react';
import styled from 'styled-components';

import { useI18nContext } from '../../react-i18n';
import { Translate, __ } from '../../react-i18n';

const ColoredCategory = styled.b`
  color: #aaa;
`;

export default ({ categories, languages = [] }: { categories: string[]; languages?: string[] }) => {
  const i18nContext = useI18nContext();

  let languageNames = languages
    .sort((a: any, b: any) => a._id.localeCompare(b._id))
    .reduce((acc: string[], val: any) => [...acc, val.name], [])
    .join(', ');
  let categoryNames = categories
    .sort((a: any, b: any) => a.slug.localeCompare(b.slug))
    .reduce((acc: string[], val: any) => [...acc, val.name], [])
    .join(', ');

  languageNames = languageNames.replace(/,([^,]*)$/, ` ${__({ id: 'or', defaultMessage: 'or' }, i18nContext)} $1`);
  categoryNames = categoryNames.replace(/,([^,]*)$/, ` ${__({ id: 'and', defaultMessage: 'and' }, i18nContext)} $1`);

  if (languages && languages.length > 0) {
    return (
      <Translate
        id="seo.category-language.title"
        defaultMessage="Map of remote {categoryNames} places where you speak {languageNames}"
        values={{
          categoryNames: <ColoredCategory>{categoryNames}</ColoredCategory>,
          languageNames: <ColoredCategory>{languageNames}</ColoredCategory>,
        }}
      />
    );
  }
  return (
    <Translate
      id="seo.category.title"
      defaultMessage="Map of remote {categoryNames} places"
      values={{
        categoryNames: <ColoredCategory>{categoryNames}</ColoredCategory>,
      }}
    />
  );
};
