import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';

export const blink = keyframes`
  50%  { background: rgba(255, 255, 255, 1); }
`;

interface IDrawerProps {
  children: any;
  expanded: boolean;
}

const Drawer = styled('div')<IDrawerProps>`
  ${({ expanded, theme }) => css`
    z-index: 1;
    transition: transform 0.5s ease-in-out;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.3);

    ${expanded &&
      css`
        transform: translateY(0%);
        background: rgba(255, 255, 255, 1);
        border-radius: 10px 10px 0 0;
      `}
    ${!expanded &&
      css`
        transform: translateY(calc(100% - 30px));
        background: rgba(255, 255, 255, 0.6);
        &:hover {
          background: rgba(255, 255, 255, 0.8);
        }
      `}
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: 60%;
    text-align: center;

    animation: 1s ${blink} 5 alternate;

    ${theme.breakpoint('tablet')`
      height: 40%;
    `}
  `}
`;

const DrawerToggler = styled('a')`
  font-weight: bold;
  line-height: 30px;
  cursor: pointer;
  color: black;

  &:active,
  &:hover,
  &:focus {
    color: #333;
  }
`;

const DrawerContent = styled('div')`
  overflow-y: auto;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 60px;
`;

const DrawerWrapper = ({ children, ...props }: IDrawerProps) => <Drawer {...props}>{children}</Drawer>;

DrawerWrapper.Toggler = DrawerToggler;
DrawerWrapper.Content = DrawerContent;

export default DrawerWrapper;
