import styled from 'styled-components';

import { Button } from '../ui';

export default styled(Button)`
  border-color: transparent;
  border: 2px solid rgba(0, 0, 0, 0.2);
  & {
    color: black !important;
  }
`;
