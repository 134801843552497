import * as PropTypes from 'prop-types';
import * as React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../icons';

import { Translate } from '../react-i18n';
import { Box, Flex } from '../ui';

const LANGUAGES = process.env.REACT_APP_I18N_LANGUAGES!.split(',');

const NavigationBar = styled.nav`
  ${() => css`
    display: flex;
    align-items: space-around;
    flex: 1;
  `}
`;

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`;

const BurgerMenu = styled(Icon).attrs({
  name: 'burger',
})`
  ${({ theme }) => css`
    height: 60px;
    width: 40px;
    margin-left: auto;
    cursor: pointer;
    padding-right: 5px;
    ${theme.breakpoint('tablet')`
      display: none;
      pointer-events: none;
    `}
  `}
`;

interface AsideMenuWrapperProps {
  show?: boolean;
}

const AsideMenuWrapper = styled('aside')<AsideMenuWrapperProps>`
  ${({ show }) => css`
    background: white;
    position: fixed;
    min-width: 200px;
    max-width: 300px;
    top: 0;
    bottom: 0;
    right: 0;
    transform: translateX(150%);
    transition: 0.3s ease-in-out;
    overflow: auto;
    /* Be on top of all others elements in fixed position */
    z-index: 1000;
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    will-change: transform;
    ${show &&
      css`
        transform: translateX(0%);
      `}
  `}
`;

const AsideMenuList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  a {
    display: block;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
`;
const AsideMenuListItem = styled.li`
  ${({ theme }) => css`
    margin: 0;
    padding: 10px;
    a {
      color: ${theme.colors.main};
    }

    &:hover {
      background: ${theme.colors.main};
      a {
        color: white;
      }
    }
  `}
`;
const AsideMenuListLanguage = styled.li`
  ${({ theme }) => css`
    margin: 0;
    padding: 10px;
    a {
      color: ${theme.colors.accentDark};
      display: flex;
      align-items: center;
      img {
        margin-right: 2px;
      }
    }

    &:hover {
      background: ${theme.colors.accentDark};
      a {
        color: white !important;
      }
    }
  `}
`;

// @ts-ignore
const AppMenu = styled(Flex).attrs({
  alignItems: 'stretch',
})`
  ${({ theme }) => css`
    display: none;
    ${theme.breakpoint('tablet')`
    display: flex;
  `}
  `}
  flex: 1;
`;

// @ts-ignore
const LanguageMenu = styled(Flex).attrs({
  alignItems: 'center',
  marginHorizontal: 'xxsmall',
})`
  ${({ theme }) => css`
    display: none;
    ${theme.breakpoint('tablet')`
      display: flex;
      img {
        margin-top: 0!important;
        margin-bottom: 0!important;
        cursor: pointer;
      }
    `}
  `}
`;

interface AppPageLinkProps {
  active?: string;
}

const linkCss = css`
  ${({ theme }) => css`
    font-family: Muli;
    text-transform: none;
    letter-spacing: 0px;
    font-weight: 700;
    font-style: normal;
    color: ${theme.colors.dark};
    font-size: 14px;
    transition: color 0.8s;

    display: flex;
    align-items: center;
    padding: 8px;

    &:hover {
      text-decoration: none;
      color: ${theme.colors.main};
    }
  `}
`;

// const AppPageLink = styled(Link)<AppPageLinkProps>`
//   ${linkCss}
//   ${({ theme, active }) => css`
//     ${active &&
//       css`
//         color: ${theme.colors.main};
//       `}
//   `}
// `;
const AppPageA = styled('a')<AppPageLinkProps>`
  ${linkCss}
`;

const CloseBox = styled(Box).attrs({
  textAlign: 'right',
})`
  font-size: 20px;
  margin-right: 5px;
  margin-top: 5px;
`;

interface Props {
  active?: 'spot';
}

interface State {
  showAside?: boolean;
}

interface Context {
  locale: string;
  otherLocaleNames: [
    {
      value: string;
      text: string;
    }
  ];
}

const getUrl = (locale: string) => (type: 'our-family' | 'blog' | 'contact') => {
  switch (type) {
    case 'our-family':
      switch (locale) {
        case 'fr':
          return '/fr/notre-famille/';
        case 'es':
          return '/es/nuestra-familia/';
        default:
          return '/our-family/';
      }
    case 'blog':
      switch (locale) {
        case 'fr':
          return '/fr/blog';
        case 'es':
          return '/es/blog';
        default:
          return '/blog/';
      }
    case 'contact':
      switch (locale) {
        case 'fr':
          return '/fr/contact/';
        case 'es':
          return '/es/contact/';
        default:
          return '/contact/';
      }
    default:
      return '';
  }
};

class Layout extends React.Component<Props, State> {
  public static contextTypes = {
    locale: PropTypes.string,
    switchLocale: PropTypes.func,
    otherLocaleNames: PropTypes.array,
  };

  public state = {
    showAside: false,
  };

  public onLanguageChange = ({ currentTarget }: { currentTarget: any }) => {
    this.context.switchLocale(currentTarget.getAttribute('data-language'));
    window.location.reload();
    // this.setState({ showAside: false });
  };

  public toggleAside = () =>
    this.setState((prevState: State) => ({
      showAside: !prevState.showAside,
    }));

  public render() {
    const { locale, otherLocaleNames }: Context = this.context;
    const { showAside } = this.state;
    const getLocaleUrl = getUrl(locale);

    return (
      <NavigationBar>
        <BurgerMenu onClick={this.toggleAside} />
        <AsideMenuWrapper show={showAside}>
          <CloseBox>
            <ClickableIcon name="close" onClick={this.toggleAside} />
          </CloseBox>

          <AsideMenuList>
            <AsideMenuListItem onClick={this.toggleAside}>
              <a href={getLocaleUrl('our-family')}>
                <Translate id="our-family" defaultMessage="Our family" />
              </a>
            </AsideMenuListItem>
            <AsideMenuListItem onClick={this.toggleAside}>
              <a href={getLocaleUrl('blog')}>
                <Translate id="blog" defaultMessage="Blog" />
              </a>
            </AsideMenuListItem>
            <AsideMenuListItem onClick={this.toggleAside}>
              <a href={getLocaleUrl('contact')}>
                <Translate id="contact" defaultMessage="Contact" />
              </a>
            </AsideMenuListItem>
          </AsideMenuList>
          <h5>
            <Translate id="languages.other" defaultMessage="other languages" textTransform="capitalize" />
          </h5>
          <AsideMenuList>
            {otherLocaleNames.map(
              ({ value, text }) =>
                value !== locale && (
                  <AsideMenuListLanguage key={value}>
                    <a onClick={this.onLanguageChange} data-language={value}>
                      {value === 'fr' ? '🇫🇷' : value === 'es' ? '🇪🇸' : '🇬🇧'} {text}
                    </a>
                  </AsideMenuListLanguage>
                )
            )}
          </AsideMenuList>
        </AsideMenuWrapper>
        <AppMenu>
          {/* <AppPageA href={getLocaleUrl('our-family')}>
            <Translate id="our-family" defaultMessage="Our family" />
          </AppPageA> */}
          {/* <AppPageA href={getLocaleUrl('blog')}>
            <Translate id="blog" defaultMessage="Blog" />
          </AppPageA> */}
          <AppPageA href={'mailto:hello@remote-family.com'}>
            <Translate id="contact" defaultMessage="Contact" />
          </AppPageA>
        </AppMenu>
        <LanguageMenu>
          {LANGUAGES.map(
            (lang: string) =>
              lang !== locale && (
                <a onClick={this.onLanguageChange} data-language={lang}>
                  {lang === 'fr' ? '🇫🇷' : lang === 'es' ? '🇪🇸' : '🇬🇧'}
                </a>
              )
          )}
        </LanguageMenu>
      </NavigationBar>
    );
  }
}

export default Layout;
