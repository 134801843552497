import * as React from 'react';
import styled, { css } from 'styled-components';

const component: <Props>(Tag: any) => React.SFC<Props> = (Tag: any) => (props: any) => <Tag {...props}>{props.children}</Tag>;

interface AsProps {
  as?: any;
  showAside?: boolean;
}

interface AsPropsAside extends AsProps {
  fullWidth?: boolean;
}

const ASIDE_WIDTH = '200px';
const MARGIN_WIDTH = '0';

const TwoColumnCollapsible = styled<any>('div')`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const Aside = styled(component<AsPropsAside>(({ as = 'aside', showAside, fullWidth, ...props }: AsPropsAside) => React.createElement(as, props)))`
  ${({ showAside, fullWidth, theme }) => css`
    width: ${fullWidth ? '100%' : '408px'};
    max-width: 100vw;
    z-index: 2;
    transition: all 0.6s;
    transform: translateX(${showAside ? 0 : '-100%'});
    position: absolute;
    top: 0;
    bottom: 0;
    background: white;
    display: flex;
    ${showAside &&
      css`
        box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.3);
      `}

    ${CollapseButton} {
      ${fullWidth &&
        css`
          display: none;
        `}
      right: 0;
      ${!showAside &&
        css`
          right: -25px;
        `}
    }
    ${theme.breakpoint('tablet')`
      ${CollapseButton} {
        right: -25px;
      }
    `}
  `}
`;

const CaretLeft = styled.span`
  ${({ theme }: { theme: any }) => css`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent ${theme.colors.mainDark} transparent transparent;
  `}
`;

const CaretRight = styled.span`
  ${({ theme }: { theme: any }) => css`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent ${theme.colors.mainDark};
  `}
`;

const CollapseButton = styled.button`
  ${({ theme }) => css`
    outline: none;
    position: absolute;
    top: 5px;
    right: 0;
    width: 25px;
    height: 48px;
    cursor: pointer;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    z-index: 1; /* needed to make it clickable when it's over the vertical scrollbar on mobile */
    border: 1px solid #bbb !important;

    &:hover,
    &:active,
    &:focus {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    }
    &:active {
      border: #888 !important;
    }

    ${theme.breakpoint('tablet')`
      right: -15px;
    `}
  `}
`;

const Main = styled(component<AsProps>(({ as = 'main', showAside, ...props }: AsProps) => React.createElement(as, props)))`
  ${({ showAside }) => css`
    flex: 1;
    margin: ${MARGIN_WIDTH};
    ${showAside &&
      css`
        margin-left: calc(${ASIDE_WIDTH} + ${MARGIN_WIDTH});
      `}
  `}
`;

interface ITwoColumnCollapsibleContext {
  showAside?: boolean;
  toggleAside: () => void;
}

export const Context = React.createContext<Partial<ITwoColumnCollapsibleContext>>({});

const TwoColumnCollapsibleWrapper = (props: { children: any }) => {
  const [showAside, setShowAside] = React.useState(true);
  const toggleAside = () => setShowAside(!showAside);

  return (
    <Context.Provider value={{ showAside, toggleAside }}>
      <TwoColumnCollapsible>{props.children}</TwoColumnCollapsible>
    </Context.Provider>
  );
};

const AsideContent = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const AsideWrapper = ({ fullWidth, children, ...rest }: { children: any; fullWidth: boolean }) => {
  const { showAside, toggleAside } = React.useContext(Context);
  return (
    <Aside showAside={showAside} fullWidth={fullWidth} {...rest}>
      <CollapseButton onClick={toggleAside}>
        {showAside && <CaretLeft />}
        {!showAside && <CaretRight />}
      </CollapseButton>
      <AsideContent>{children}</AsideContent>
    </Aside>
  );
};

const MainWrapper = ({ children, ...rest }: { children: any }) => {
  const { showAside } = React.useContext(Context);
  return (
    <Main showAside={showAside} {...rest}>
      {children}
    </Main>
  );
};

TwoColumnCollapsibleWrapper.Aside = AsideWrapper;
TwoColumnCollapsibleWrapper.Main = MainWrapper;

export default TwoColumnCollapsibleWrapper;
