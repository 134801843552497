import { useContext } from 'react';
import { I18nContext } from '../IntlProvider/IntlProvider';

export interface Ii18nContext {
  locales?: string[];
  messages?: any;
  locale?: string;
  defaultLocale?: string;
  localeNames?: Array<{ key: string; value: string; text: string }>;
  otherLocaleNames?: Array<{ key: string; value: string; text: string }>;
  switchLocale?: (locale: string) => void;
  languageUrlCodes?: any;
  languageUrlParam?: any;
}

const useI18nContext: () => Ii18nContext = () => useContext(I18nContext);

export default useI18nContext;
