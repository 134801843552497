import * as React from 'react';
import { withTheme } from 'styled-components';
import { Helmet } from 'react-helmet';
import { useI18nContext, __ } from '../react-i18n';

const seoImageURL = (file: string) => `https://www.remote-family.com/assets${file}`;

const getMetaTags = ({ title, description, type, image, theme }: any) => {
  const metaTags = [];

  if (type) {
    metaTags.push({ property: 'og:type', content: type });
  }

  return metaTags;
};

const getMicrodataScripts = (microdata: any) => {
  if (!microdata) {
    return [];
  }

  return [
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify(
        {
          '@context': 'http://schema.org',
          ...microdata,
        },
        null,
        2
      ),
    },
  ];
};

export default withTheme(
  React.memo(
    ({
      init = false,
      title = null,
      description = null,
      type = null,
      image = null,
      microdata = null,
      script = [],
      meta = [],
      link = [],
      theme,
      children,
    }: any) => {
      const { languageUrlCodes, languageUrlParam, defaultLocale, locale, locales, messages } = useI18nContext();

      const getAlternateLanguagesLinks = () => {
        const urls = [];

        const alternateLanguagesKeys = Object.keys(languageUrlCodes);

        for (const alternateLanguagesKey of alternateLanguagesKeys) {
          const targetAlternateLanguage = languageUrlCodes[alternateLanguagesKey];
          const extractedUrl = new URL(window.location.href);
          if (targetAlternateLanguage === defaultLocale) {
            extractedUrl.searchParams.delete(languageUrlParam);
          } else {
            extractedUrl.searchParams.set(languageUrlParam, targetAlternateLanguage);
          }

          if (alternateLanguagesKey === defaultLocale) {
            // use fallback for not supported languages
            // https://webmasters.googleblog.com/2013/04/x-default-hreflang-for-international-pages.html
            urls.push({ rel: 'alternate', hreflang: 'x-default', href: extractedUrl.href });
          }

          urls.push({ rel: 'alternate', hreflang: alternateLanguagesKey, href: extractedUrl.href });
        }

        return urls;
      };

      const shareImage = image || seoImageURL('/social/facebook-card.jpg');

      const metas = [...getMetaTags({ title, description, type, image, theme }), ...meta];
      const links = [...getAlternateLanguagesLinks(), ...link];

      let helmetProps: any = {};

      if (init) {
        helmetProps = {
          defer: false,
          htmlAttributes: {
            lang: locale,
            prefix: 'og: http://ogp.me/ns#',
            titleTemplate: '%s - Remote Family',
            defaultTitle: __(
              { id: 'seo.title.default', defaultMessage: 'Map of best remote places in the world for your passions' },
              { locale, locales, messages }
            ),
          },
        };
      }

      if (title) {
        helmetProps.title = title;
      }

      if (description) {
        helmetProps.description = description;
      }

      return (
        <Helmet meta={metas} script={getMicrodataScripts(microdata).concat(script)} link={links} {...helmetProps}>
          {title && <title>{title}</title>}
          {description && <meta name="description" content={description} />}
          <meta name="theme-color" content={theme.colors.main} />

          {title && <meta property="og:title" content={title} />}
          {description && <meta property="og:description" content={description} />}
          <meta name="og:site_name" content="Remote Family" />

          {title && <meta property="twitter:title" content={title} />}
          {description && <meta name="twitter:description" content={description} />}
          <meta name="twitter:creator" content="@remote_family" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@remote_family" />

          <meta itemProp="image" content={shareImage} />
          <meta property="og:image" content={shareImage} />
          <meta property="og:image:width" content="1312" />
          <meta property="og:image:height" content="485" />
          <meta name="twitter:image" content={shareImage} />
          {children}
        </Helmet>
      );
    }
  )
);
