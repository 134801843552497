import React from 'react';
import { Route } from 'react-router-dom';

import Login from './Login';

const Ok = () => <div>Congrats</div>;
const Ko = () => <div>Ko</div>;

const Routes = [
  <Route exact={true} key="/login" path="/spot/login" component={Login} public />,
  <Route exact={true} key="/ok" path="/spot/ok" component={Ok} public />,
  <Route exact={true} key="/error" path="/spot/error" component={Ko} public />,
];

export default Routes;
