import React, { useEffect, useState } from 'react';
import axios from 'axios';

import MarkerCategory from '../../../app-components/MarkerCategory';
import Popup from '../../../app-components/Popup';
import { Translate } from '../../../react-i18n';
import { Icon } from '../../../ui';

interface IProps {
  airports: any;
}

const COLOR = '#333';
const INVERSE_COLOR = '#FFF';

const getAffiliateAirportLink = (origin: string, destination: string) => {
  if (!origin) {
    return 'https://jetradar.com/?marker=247912';
  }
  return `https://jetradar.com/route/${origin}/${destination}/?marker=247912`;
};

export default ({ airports, ...props }: IProps) => {
  const [originAirport, setOriginAirport] = useState('');

  useEffect(() => {
    axios.get('https://api.ipify.org/?format=json').then(({ data: { ip } }) => {
      axios.get(`https://www.travelpayouts.com/whereami?locale=en&ip=${ip}`).then(({ data }) => {
        setOriginAirport(data.iata);
      });
    });
  }, []);

  return airports.map((airport: any) => (
    <MarkerCategory
      key={airport.name}
      position={airport.coordinates}
      icon={'airport' as any}
      color={COLOR}
      inverseColor={INVERSE_COLOR}
      shape="square"
      {...props}
    >
      <Popup color={COLOR} inverseColor={INVERSE_COLOR}>
        <Popup.Title>
          {airport.airport_name}
          <span>{airport.iata}</span>
        </Popup.Title>
        <Popup.Address>{airport.name}</Popup.Address>
        <Popup.CTA as="a" href={getAffiliateAirportLink(originAirport, airport.iata)} target="_blank" rel="sponsored">
          <Icon name="airport" />
          <Translate id="airport:book:action" defaultMessage="Book a flight to this airport" />
        </Popup.CTA>
      </Popup>
    </MarkerCategory>
  ));
};
