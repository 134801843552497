import React, { useState, useContext, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { uniq, get } from 'lodash/fp';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { Translate, __, useI18nContext } from '../../react-i18n';
import SpotLayout, { SpotLayoutContext, SpotLayoutMainContext } from '../../app-containers/SpotLayout';
import { Button, Flex, Box, Boxed, Color, Grid } from '../../ui';
import MapCategories from '../../app-components/MapCategories';
import CategoryPicker from '../../app-components/CategoryPicker';
import LanguagePicker from '../../app-components/LanguagePicker';
import DiseasePicker from '../../app-components/DiseasePicker';
import TemperaturePicker from '../../app-components/TemperaturePicker';
import CostOfLifePicker from '../../app-components/CostOfLifePicker';
import TimezonePicker from '../../app-components/TimezonePicker';
import ModalBecomeMember from '../../app-components/ModalBecomeMember';
import Category from '../../app-components/Category';
import SpotAreas from '../../app-components/SpotAreas';
import { isMobileDevice } from '../../utils/detection';
import SpotSEO from './SEO';
import TitleTranslation from './TitleTranslation';

const CategoriesCheckboxes = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  marginHorizontal: 'xsmall',
  marginVertical: 'xsmall',
  wrap: 'wrap',
})`
  .field {
    margin-top: -5px;
    margin-right: 5px;
  }
`;

const ColoredWrapper = styled(Box).attrs({
  paddingVertical: 'medium',
})<{ faded?: boolean }>`
  ${({ theme, faded = false }) => css`
    background: repeating-linear-gradient(
      45deg,
      ${faded ? '#f9f9f9' : '#e2e1e1'},
      ${faded ? '#f9f9f9' : '#e2e1e1'} 40px,
      ${faded ? '#f9f9f9' : '#DDD'} 40px,
      ${faded ? '#f9f9f9' : '#DDD'} 80px
    );
  `}
`;

const H2 = styled.h2<any>`
  ${({ textAlign, theme }) => css`
    text-align: ${textAlign};
    padding: ${theme.paddings.medium};
    padding-bottom: ${theme.paddings.xxsmall};
    font-size: 26px;
    margin: 0;
  `}
`;
const H3 = styled.h3<any>`
  ${({ textAlign, theme }) => css`
    padding: ${theme.paddings.medium};
    text-align: ${textAlign};
    margin: 0;
  `}
`;
const H5 = styled.h5<any>`
  ${({ textAlign, theme }) => css`
    padding: ${theme.paddings.medium};
    text-align: ${textAlign};
    margin: 0;
  `}
`;

const HSubtitle = ({ children }: any) => (
  <Boxed>
    <Box marginBottom="medium" textAlign="center">
      <Color color="faded">{children}</Color>
    </Box>
  </Boxed>
);

const Hero = styled('div')`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/assets/hero/hero-valley-remote-family.jpg');

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  display: flex;

  color: white;

  h2 {
    color: white;
  }
`;

const SpotLayoutAsideFlex = styled(SpotLayout.Aside)<{ fullWidth: boolean; padded: boolean }>`
  ${({ fullWidth, padded }) => css`
    ${padded &&
      css`
        padding-bottom: 60px;
      `}
    ${!fullWidth &&
      css`
        > div {
          display: flex;
          flex-direction: column;
          overflow-y: hidden;
        }
      `}
  `}
`;

const CountryPicker = styled('div')<{ fullWidth: boolean }>`
  ${({ fullWidth }) => css`
    ${!fullWidth &&
      css`
        flex: 1;
        overflow-y: auto;
      `}
    ${fullWidth &&
      css`
        min-height: '300px';
      `}
  `}
`;

const FindYourSpot = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 2em 1em;
  margin: 0;
  box-shadow: 0 -1px 7px 2px rgba(0, 0, 0, 0.3);
`;

const GET_COUNTRIES = gql`
  {
    countries {
      count
      nodes {
        _id
        name
        costOfLife
        averageTemperature
      }
    }
  }
`;

export default (props: any) => {
  const searchParams = new URLSearchParams(props.location.search);
  const paramTemperature = searchParams.get('temperature');
  const paramTimezone = searchParams.get('timezone');
  const paramCostOfLife = searchParams.get('costOfLife');

  const { data } = useQuery(GET_COUNTRIES);
  const countries = get('countries.nodes')(data) || [];
  const i18nContext = useI18nContext();
  const [fullWidth, setFullWidth] = useState(true);
  const [categoriesPicked, setCategoriesPicked] = useState(false);
  const [geohashCode, setGeoHashCode] = useState('');
  const [bounds, setBounds] = useState(null);
  const [categories, setCategories] = useState<any[]>([]);
  const [areas, setAreas] = useState<any[]>([]);
  const [languages, setLanguages] = useState<any[]>([]);
  const [diseases, setDiseases] = useState<any[]>([]);
  const [temperature, setTemperature] = useState<any[]>(paramTemperature ? paramTemperature.split(',').map(parseFloat) : []);
  const [costOfLife, setCostOfLife] = useState<any[]>(paramCostOfLife ? paramCostOfLife.split(',').map(parseFloat) : []);
  const [timezone, setTimezone] = useState<any[]>(paramTimezone ? paramTimezone.split(',').map(parseFloat) : []);
  const { toggleAside } = useContext(SpotLayoutContext);
  const { setTitle } = useContext(SpotLayoutMainContext);

  if (typeof window === 'undefined') {
    return null;
  }

  useEffect(() => {
    if (props.match.params.geohash && props.match.params.geohash !== geohashCode) {
      setFullWidth(false);
      setGeoHashCode(props.match.params.geohash);
      setBounds(JSON.parse(props.match.params.bounds));
    }
  }, [geohashCode, props.match.params.geohash]);

  useEffect(() => {
    if (categories.length > 0) {
      setTitle(<TitleTranslation categories={categories} languages={languages} />);
    } else {
      setTitle(<Translate id="seo.title.default" defaultMessage="Map of best remote places in the world for your passions" />);
    }
  }, [categories, languages]);

  const getUrl = (uri: string = '') => {
    const sortedCategories = categories.sort((a: any, b: any) => (b.name < a.name ? 1 : -1));
    const sortedLanguages = languages.sort((a: any, b: any) => (b.name < a.name ? 1 : -1));
    const sortedDiseases = diseases.sort((a: any, b: any) => (b.name < a.name ? 1 : -1));

    const categoryUrlParts = sortedCategories.reduce((acc: string[], val: any) => [...acc, val.slug], []);
    const languageUrlParts = sortedLanguages.reduce((acc: string[], val: any) => [...acc, val._id], []);
    const diseaseUrlParts = sortedDiseases.reduce((acc: string[], val: any) => [...acc, val._id], []);

    const newSearchParams = new URLSearchParams();
    if (temperature.length) {
      newSearchParams.set('temperature', temperature.join(','));
    }
    if (costOfLife.length) {
      newSearchParams.set('costOfLife', costOfLife.join(','));
    }
    if (timezone.length) {
      newSearchParams.set('timezone', timezone.join(','));
    }
    if (diseases.length) {
      newSearchParams.set('diseases', diseaseUrlParts);
    }

    const newSearchParamsString = `${newSearchParams}`;
    const url = `/spot/${uniq(categoryUrlParts).join(',')}/${uniq(languageUrlParts).join(',')}/`.replace(/\/$/, '').replace(/\/$/, '');
    return `${url}${uri}${newSearchParamsString ? `?${newSearchParamsString}` : ''}`;
  };

  const onCategoriesLoaded = (type?: string) => (loadedCategories: any[]) => {
    if (loadedCategories.length > 0) {
      setCategories(type ? uniq([...categories.filter((c: any) => c.type !== type), ...loadedCategories]) : loadedCategories);
      setCategoriesPicked(true);
    }
  };
  const onCategoriesChanged = (type?: string) => (changedCategories: any[]) => {
    setCategories(type ? uniq([...categories.filter((c: any) => c.type !== type), ...changedCategories]) : changedCategories);
    setCategoriesPicked(false);
  };

  const onLanguagesLoaded = (loadedLanguages: any[]) => {
    if (loadedLanguages.length > 0) {
      setLanguages(loadedLanguages);
    }
  };
  const onLanguagesChanged = (changedLanguages: any[]) => {
    setLanguages(changedLanguages);
    setCategoriesPicked(false);
  };

  const onDiseasesLoaded = (loadedDiseases: any[]) => {
    if (loadedDiseases.length > 0) {
      setDiseases(loadedDiseases);
    }
  };
  const onDiseasesChanged = (changedDiseases: any[]) => {
    setDiseases(changedDiseases);
    setCategoriesPicked(false);
  };

  const onTemperatureLoaded = (loadedTemperature: any[]) => {
    if (loadedTemperature.length > 0) {
      setTemperature(loadedTemperature);
    }
  };
  const onTemperatureChanged = (changedTemperature: any[]) => {
    setTemperature(changedTemperature);
    setCategoriesPicked(false);
  };

  const onCostOfLifeLoaded = (loadedCostOfLife: any[]) => {
    if (loadedCostOfLife.length > 0) {
      setCostOfLife(loadedCostOfLife);
    }
  };
  const onCostOfLifeChanged = (changedCostOfLife: any[]) => {
    setCostOfLife(changedCostOfLife);
    setCategoriesPicked(false);
  };

  const onTimezoneLoaded = (loadedTimezone: any[]) => {
    if (loadedTimezone.length > 0) {
      setTimezone(loadedTimezone);
    }
  };
  const onTimezoneChanged = (changedTimezone: any[]) => {
    setTimezone(changedTimezone);
    setCategoriesPicked(false);
  };

  const findSpotsAreas = () => {
    setCategoriesPicked(true);
    props.history.push(`${getUrl()}#pick-country`);
  };

  const onSelectSpotArea = ({ _id: code, bounds: areaBounds, countries: countryNames, ...rest }: any, hash: string) => {
    props.history.push(`${getUrl(`/${code}/${countryNames}/${JSON.stringify(areaBounds)}`)}#${hash}`);
    setFullWidth(false);
    if (isMobileDevice() && toggleAside) {
      toggleAside();
    }
  };

  const goBackToCheckboxSelection = () => {
    setFullWidth(true);
    setCategoriesPicked(false);
    props.history.push(getUrl());
  };

  const onMapMove = (newBounds: any) => {
    props.history.replace(props.history.location.pathname.replace(/\[\[.*\]\]/gi, JSON.stringify(newBounds)));
  };

  const categoryIds = categories.reduce((acc: string[], val: any) => [...acc, val._id], []);
  const languageIds = languages.reduce((acc: string[], val: any) => [...acc, val._id], []);
  const diseaseIds = diseases.reduce((acc: string[], val: any) => [...acc, val._id], []);

  return (
    <React.Fragment>
      <SpotSEO categories={categories} languages={languages} />
      <SpotLayoutAsideFlex fullWidth={fullWidth} padded={!categoriesPicked && fullWidth}>
        {fullWidth && (
          <>
            <Hero>
              <Boxed>
                <Grid>
                  <Grid.Item flex="1">
                    <Box padding="medium" textAlign="left">
                      <h2>
                        <Translate id="spots:intro:title" defaultMessage="Find the best place to live and enjoy your passions." />
                      </h2>
                      <Translate
                        id="spots:intro:description1"
                        defaultMessage="Remote-Family allows you to instantly discover all the spots on Earth that corresponds to your desires, your passions, your hobbies... "
                      />
                      <br />
                      <Translate
                        id="spots:intro:description2"
                        defaultMessage="The place where all the needs of the members of your family or group will be fulfilled. No one will be forgotten!"
                      />
                      <br />
                      <br />
                      <Translate id="spots:intro:action" defaultMessage="So let's start" />
                    </Box>
                  </Grid.Item>
                  <Grid.Item flex="1">&nbsp;</Grid.Item>
                </Grid>
              </Boxed>
            </Hero>
            <Boxed>
              <Box padding="large">
                <p>
                  <Translate id="spots:description.1" defaultMessage="If you are looking for a place where you can flourish, we have the solution!" />
                </p>
                <p>
                  <Translate
                    id="spots:description.2"
                    defaultMessage="In 3 steps you will discover this incredible place, your peace of mind that will allow the whole family to live serenely. This paradise on earth exists, all you have to do is find it!"
                  />
                </p>
                <p>
                  <Translate
                    id="spots:description.3"
                    defaultMessage="Fill in your passions, your needs and refine your search. Then, walk around the map to discover the spots with which you are 100% competitive."
                  />
                </p>
                <p>
                  <Translate id="spots:description.4" defaultMessage="So are you ready?" />
                </p>
                <p>
                  <Translate id="spots:description.action" defaultMessage="Let's go!" />
                </p>
              </Box>
            </Boxed>

            <H2 textAlign="center">
              <Translate id="categories:passion:pick.title" defaultMessage="1. Pick your passions" />
            </H2>
            <HSubtitle>
              <Translate
                id="categories:passion:pick.description"
                defaultMessage="Choose what really makes each member of the family happy in life and that you absolutely want in your future remote destination"
              />
            </HSubtitle>
            <ColoredWrapper>
              <Boxed>
                <CategoryPicker
                  defaultCategories={props.match.params.categories}
                  onChange={onCategoriesChanged('passion')}
                  onLoad={onCategoriesLoaded('passion')}
                  type="passion"
                />
              </Boxed>
            </ColoredWrapper>
            <H2 textAlign="center">
              <Translate id="categories:need:pick.title" defaultMessage="2. Pick your needs" />
            </H2>
            <HSubtitle>
              <Translate
                id="categories:need:pick.description"
                defaultMessage="Choose what you absolutely need for your family to feel safe and enjoy their future destination"
              />
            </HSubtitle>
            <ColoredWrapper>
              <Boxed>
                <CategoryPicker
                  defaultCategories={props.match.params.categories}
                  onChange={onCategoriesChanged('need')}
                  onLoad={onCategoriesLoaded('need')}
                  type="need"
                />
              </Boxed>
            </ColoredWrapper>
            <H2 textAlign="center">
              <Translate id="countries:filter.title" defaultMessage="3. Fine tune your search" />
            </H2>
            <HSubtitle>
              <Translate id="countries:filter:description" defaultMessage="Be precise. Find the real perfect spot" />
            </HSubtitle>
            <ColoredWrapper>
              <Boxed>
                <CategoriesCheckboxes>
                  <Category
                    direction="column"
                    checked={props.match.params.languages && props.match.params.languages.length > 0}
                    color="#888"
                    inverseColor="#FFF"
                    backgroundUrl="#FFF"
                  >
                    <H5 textAlign="center">
                      <Translate id="languages:pick:title" defaultMessage="Languages you wish to be spoken where you'll go" />
                    </H5>
                    <LanguagePicker
                      defaultLanguages={props.match.params.languages}
                      onChange={onLanguagesChanged}
                      onLoad={onLanguagesLoaded}
                      placeholder={__({ id: 'languages:placeholder', defaultMessage: 'Any' }, i18nContext)}
                    />
                  </Category>
                  <Category direction="column" checked={searchParams.has('diseases')} color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="diseases:pick:title" defaultMessage="Diseases you wish to avoid" />
                    </H5>
                    <DiseasePicker
                      defaultDiseases={searchParams.get('diseases') || ''}
                      onChange={onDiseasesChanged}
                      onLoad={onDiseasesLoaded}
                      placeholder={__({ id: 'diseases:placeholder', defaultMessage: 'None' }, i18nContext)}
                    />
                    <p>
                      <small>
                        *
                        <Translate
                          id="diseases:asterisk"
                          defaultMessage="Data has been gathered in Sept 2019. We cannot be held responsible for any change"
                        />
                      </small>
                    </p>
                  </Category>
                  <Category direction="column" checked={searchParams.has('temperature')} color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="temperature:pick:title" defaultMessage="Cold or hot average temperature" />
                    </H5>
                    <TemperaturePicker
                      countries={countries}
                      defaultTemperature={temperature}
                      onChange={onTemperatureChanged}
                      onLoad={onTemperatureLoaded}
                    />
                  </Category>
                  <Category direction="column" checked={searchParams.has('timezone')} color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="timezone:pick:title" defaultMessage="Choose your timezone" />
                    </H5>
                    <TimezonePicker defaultTimezone={timezone} onChange={onTimezoneChanged} onLoad={onTimezoneLoaded} />
                  </Category>
                  <Category direction="column" color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="investment:pick:title" defaultMessage="Real estate investment possible" />
                    </H5>
                    <ModalBecomeMember />
                  </Category>
                  <Category direction="column" checked={searchParams.has('costOfLife')} color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate
                        id="life-cost:pick:title"
                        defaultMessage="Define the cost of living in the country. For information we use a 50 index from France"
                      />
                    </H5>
                    <CostOfLifePicker
                      countries={countries}
                      defaultCostOfLife={costOfLife}
                      onChange={onCostOfLifeChanged}
                      onLoad={onCostOfLifeLoaded}
                    />
                  </Category>
                  <Category direction="column" color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="climate:pick:title" defaultMessage="Climate" />
                    </H5>
                    <ModalBecomeMember />
                  </Category>
                  <Category direction="column" color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="kids-friendly:pick:title" defaultMessage="Kids friendly" />
                    </H5>
                    <ModalBecomeMember />
                  </Category>
                  <Category direction="column" color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="tax-rate:pick:title" defaultMessage="Tax rate level" />
                    </H5>
                    <ModalBecomeMember />
                  </Category>
                  <Category direction="column" color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="wifi:pick:title" defaultMessage="Wifi speed" />
                    </H5>
                    <ModalBecomeMember />
                  </Category>
                  <Category direction="column" color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="safe-place:pick:title" defaultMessage="Safe place to live" />
                    </H5>
                    <ModalBecomeMember />
                  </Category>
                  <Category direction="column" color="#888" inverseColor="#FFF" backgroundUrl="#FFF">
                    <H5 textAlign="center">
                      <Translate id="political-stability:pick:title" defaultMessage="Political stability" />
                    </H5>
                    <ModalBecomeMember />
                  </Category>
                  <Category empty />
                  <Category empty />
                  <Category empty />
                </CategoriesCheckboxes>
              </Boxed>
            </ColoredWrapper>
          </>
        )}
        {!fullWidth && (
          <div>
            <H3 textAlign="left">
              <Translate id="categories.chosen.title.2" defaultMessage="Countries where you should go for if you need" />
            </H3>
            <ColoredWrapper faded onClick={goBackToCheckboxSelection}>
              <Flex wrap="wrap" marginHorizontal="xxsmall">
                <CategoryPicker
                  badges
                  defaultCategories={props.match.params.categories}
                  onChange={onCategoriesChanged()}
                  onLoad={onCategoriesLoaded()}
                />
                <LanguagePicker badges defaultLanguages={props.match.params.languages} onChange={onLanguagesChanged} onLoad={onLanguagesLoaded} />
                <DiseasePicker badges defaultDiseases={searchParams.get('diseases') || ''} onChange={onDiseasesChanged} onLoad={onDiseasesLoaded} />
                <TemperaturePicker badges defaultTemperature={temperature} onChange={onTemperatureChanged} onLoad={onTemperatureLoaded} />
                <TimezonePicker badges defaultTimezone={timezone} onChange={onTimezoneChanged} onLoad={onTimezoneLoaded} />
                <CostOfLifePicker badges defaultCostOfLife={costOfLife} onChange={onCostOfLifeChanged} onLoad={onCostOfLifeLoaded} />
              </Flex>
            </ColoredWrapper>
            <Box textAlign="right" paddingBottom="medium" paddingRight="medium" marginTop="medium">
              <Button secondary color="accent" size="xsmall" onClick={goBackToCheckboxSelection}>
                <Translate id="spot.change.action" defaultMessage="Modify my needs" />
              </Button>
            </Box>
          </div>
        )}
        <CountryPicker id="pick-country" fullWidth={fullWidth}>
          {!categoriesPicked && fullWidth && (
            <FindYourSpot textAlign="center" marginVertical="large" padding="medium">
              <Button primary color="accent" onClick={findSpotsAreas} disabled={categories.length < 1}>
                <Translate id="spot.find.action" defaultMessage="Find my sweet spot!" />
              </Button>
            </FindYourSpot>
          )}
          {categoriesPicked && (
            <>
              {fullWidth && (
                <>
                  <H2 textAlign="center">
                    <Translate id="categories.chosen.title" defaultMessage="Now, pick a country" />
                  </H2>
                  <HSubtitle>
                    <Translate
                      id="categories:chosen.description"
                      defaultMessage="Now that you chose the passions and needs of your family for your remote destination, let's find out where in the world you can go"
                    />
                  </HSubtitle>
                </>
              )}
              <ColoredWrapper faded>
                <SpotAreas
                  showSpotList={!fullWidth}
                  languages={languageIds}
                  diseases={diseaseIds}
                  categories={categoryIds}
                  temperature={temperature}
                  costOfLife={costOfLife}
                  timezone={timezone}
                  onClick={onSelectSpotArea}
                  geohashCode={geohashCode}
                  onLoad={setAreas}
                />
              </ColoredWrapper>
            </>
          )}
        </CountryPicker>
      </SpotLayoutAsideFlex>
      <SpotLayout.Main>
        {bounds && (
          <MapCategories
            categories={categoryIds}
            geohashCode={geohashCode}
            bounds={bounds}
            areas={areas}
            onMapMove={onMapMove}
            onAreaClick={onSelectSpotArea}
          />
        )}
      </SpotLayout.Main>
    </React.Fragment>
  );
};
